/* eslint-disable no-unused-vars */

export default {
  namespaced: true, // 모듈이 독립적으로 재사용

  state: {
    //변수 선언
    assetsInfo: [],
    orderList: [],
    noneExecList: [],
  },

  getters: {
    //vue 호출(get)
    getAssetsInfo: (state) => {
      return state.assetsInfo;
    },
    getOrderList: (state) => {
      return state.orderList;
    },
    getNoneExecList: (state) => {
      return state.noneExecList;
    },
  },

  mutations: {
    //vue 호출(set)
    setAssetsInfo(state, assetsInfo) {
      state.assetsInfo = assetsInfo;
    },
    setOrderList(state, orderList) {
      state.orderList = orderList;
    },
    setNoneExecList(state, noneExecList) {
      state.noneExecList = noneExecList;
    },
  },

  actions: {
    //서버단 호출
    selectAssets({ commit, state }, params) {
      //자산 및 주문 내역
      window
        .axios({
          method: "POST",
          url: "/api/order/assetsInfo",
          data: params,
        })
        .then((res) => {
          commit("setAssetsInfo", res.data.output.assetsInfo);
          commit("setOrderList", res.data.output.orderList);
          //commit("setNoneExecList", res.data.output.noneExecList);
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
    async orderExec({ commit, state }, params) {
      //주문 실행
      return window
        .axios({
          method: "POST",
          url: "/api/order/exec",
          data: params,
        })
        .then((res) => {
          return res.data;
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
    selectNoneExecList({ commit, state }, params) {
      //미체결 주문 내역
      window
        .axios({
          method: "POST",
          url: "/api/order/noneExecList",
          data: { accountNo: params },
        })
        .then((res) => {
          commit("setNoneExecList", res.data.output.noneExecList);
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
  },
};
