/* eslint-disable no-unused-vars */

export default {
  namespaced: true, // 모듈이 독립적으로 재사용

  state: {
    //변수 선언
    atprList: [],
    atprInfo: [],
    relizeList: [],
    relizeInfo: [],
    ledgrList: [],
    defrayInfo: [],
    defrayReqst: [],
  },

  getters: {
    //vue 호출(get)
    getAtprList: (state) => {
      return state.atprList;
    },
    getAtprInfo: (state) => {
      return state.atprInfo;
    },
    getRelizeList: (state) => {
      return state.relizeList;
    },
    getRelizeInfo: (state) => {
      return state.relizeInfo;
    },
    getLedgrList: (state) => {
      return state.ledgrList;
    },
    getDefrayInfo: (state) => {
      return state.defrayInfo;
    },
    getDefrayReqst: (state) => {
      return state.defrayReqst;
    },
  },

  mutations: {
    //vue 호출(set)
    setAtprList(state, atprList) {
      state.atprList = atprList;
    },
    setAtprInfo(state, atprInfo) {
      state.atprInfo = atprInfo;
    },
    setRelizeList(state, relizeList) {
      state.relizeList = relizeList;
    },
    setRelizeInfo(state, relizeInfo) {
      state.relizeInfo = relizeInfo;
    },
    setLedgrList(state, ledgrList) {
      state.ledgrList = ledgrList;
    },
    setDefrayInfo(state, defrayInfo) {
      state.defrayInfo = defrayInfo;
    },
    setDefrayReqst(state, defrayReqst) {
      state.defrayReqst = defrayReqst;
    },
  },

  actions: {
    //서버단 호출
    selectAtpr({ commit, dispatch }, params) {
      if (params == null) {
        params = {};
      }

      return dispatch("login/fetchLoginMember", null, { root: true }).then(
        (member) => {
          params.accountNo = member.accountNo;

          //자산평가
          return window
            .axios({
              method: "POST",
              url: "/api/assets/atprInfo",
              data: params,
            })
            .then((res) => {
              commit("setAtprList", res.data.input.atprList);
              commit("setAtprInfo", res.data.input.atprInfo);
            })
            .catch((error) => {
              console.log("todoInfo err : " + JSON.stringify(error));
            });
        }
      );
    },
    selectRelize({ commit, dispatch }, params) {
      if (params == null) {
        params = {};
      }

      return dispatch("login/fetchLoginMember", null, { root: true }).then(
        (member) => {
          params.accountNo = member.accountNo;

          //실현손익
          return window
            .axios({
              method: "POST",
              url: "/api/assets/relizeInfo",
              data: params,
            })
            .then((res) => {
              commit("setRelizeList", res.data.input.relizeList);
              commit("setRelizeInfo", res.data.input.relizeInfo);
            })
            .catch((error) => {
              console.log("todoInfo err : " + JSON.stringify(error));
            });
        }
      );
    },
    selectLedgr({ commit, dispatch }, params) {
      if (params == null) {
        params = {};
      }

      return dispatch("login/fetchLoginMember", null, { root: true }).then(
        (member) => {
          params.accountNo = member.accountNo;

          //거래내역
          window
            .axios({
              method: "POST",
              url: "/api/assets/ledgrInfo",
              data: params,
            })
            .then((res) => {
              commit("setLedgrList", res.data.input.ledgrList);
            })
            .catch((error) => {
              console.log("todoInfo err : " + JSON.stringify(error));
            });
        }
      );
    },
    async selectDefray({ commit, dispatch }, params) {
      if (params == null) {
        params = {};
      }
      return dispatch("login/fetchLoginMember", null, { root: true }).then(
        (member) => {
          params.accountNo = member.accountNo;

          //출금정보
          return window
            .axios({
              method: "POST",
              url: "/api/assets/defrayInfo",
              data: params,
            })
            .then((res) => {
              commit("setDefrayInfo", res.data.output.defrayInfo);
              return res.data.output.defrayInfo;
            })
            .catch((error) => {
              console.log("todoInfo err : " + JSON.stringify(error));
            });
        }
      );
    },
    async defrayReqst({ commit, state }, params) {
      //출금신청
      return window
        .axios({
          method: "POST",
          url: "/api/callDepositTransfer",
          data: params,
        })
        .then((res) => {
          // commit("setDefrayReqst", res.data.input.defrayReqst);
          return res.data;
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
    async chkPass({ commit, state }, params) {
      //거래비밀번호 체크
      return window
        .axios({
          method: "POST",
          url: "/api/assets/chkPass",
          data: params,
        })
        .then((res) => {
          // commit("setDefrayReqst", res.data.input.defrayReqst);
          return res.data.input;
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
  },
};
