import Vue from "vue";
import VueRouter from "vue-router";
import { getAccessToken, hasRole } from "../cmmn/auth";
import store from "../store";

(isAuth) => (from, to, next) => {
  const isAuthenticated = store.getters["isAuthenticated"];
  if ((isAuthenticated && isAuth) || (!isAuthenticated && !isAuth)) {
    return next();
  } else {
    console.log(isAuthenticated + "111");
    window.eventBus.$emit("showToast", "로그인 후 이용 가능합니다.");
  }
};

function getDefaultSymbolCd() {
  const savedSymbolCd = localStorage.getItem("currentSymbolCd");
  return savedSymbolCd &&
    savedSymbolCd !== "undefined" &&
    savedSymbolCd !== "null"
    ? savedSymbolCd
    : "A0001";
}

Vue.use(VueRouter);

const routes = [
  /* webpackChunkName: 단일 파일만 로드 */
  // --------------------------------------------------
  {
    path: "/index.html",
    redirect: "/",
  },
  {
    path: "/",
    name: "Main",
    component: () =>
      import(/* webpackChunkName: "Main" */ "@/components/MainView"),
    meta: { authRequired: false },
  },
  // --------------------------------------------------

  {
    // 로그인
    path: "/user/login",
    name: "UserLogin",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-a-D"),
    meta: { authRequired: false },
  },
  {
    // 로그인 시 -> 거래 비밀번호 설정
    path: "/user/login/createTradePw",
    name: "LoginCreateTradePw",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-02-C-2"),
    meta: { authRequired: false },
  },
  // --------------------------------------------------
  {
    // 아이디찾기
    path: "/user/findId/info",
    name: "FindId",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-c-D"),
    meta: { authRequired: false },
  },
  {
    // 아이디찾기 - 결과 화면
    path: "/user/findId/result",
    name: "FindIdResult",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-d-D"),
    meta: { authRequired: false },
  },
  {
    // 비밀번호 찾기
    path: "/user/findPw/info",
    name: "FindPw",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-e-C"),
    meta: { authRequired: false },
  },
  {
    // 비밀번호 찾기 - 재설정
    path: "/user/findPw/update",
    name: "FindPwMod",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-f-C"),
    meta: { authRequired: false },
  },
  // ----------------------------------------------------------------
  // 회원가입
  {
    // 회원가입 - 성공
    path: "/user/join/success",
    name: "SuccessJoin",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-03-D"),
    meta: { authRequired: false },
  },
  {
    path: "/user/join",
    redirect: "/user/join/choose",
  },
  {
    // 회원가입 - 개인, 기업 선택 화면
    path: "/user/join/choose",
    name: "ChooseJoin",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-01-D"),
    meta: { authRequired: false },
  },
  {
    // 개인 회원가입 - 약관동의
    path: "/user/join/personal/signupAgre",
    name: "PerMemSignupAgre",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-T01-C-01"),
    meta: { authRequired: false },
  },
  {
    // 개인 회원가입 - 회원정보 기입 화면
    path: "/user/join/personal/info",
    name: "PersonalInfo",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-T01-C-02"),
    meta: { authRequired: false },
  },
  {
    // 기업 회원가입 - 약관동의
    path: "/user/join/business/signupAgre",
    name: "BusniSignupAgre",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-T02-C-01"),
    meta: { authRequired: false },
  },
  {
    // 기업 회원가입 - 회원정보 기입 화면
    path: "/user/join/business/info",
    name: "BusniInfo",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-T02-C-02"),
    meta: { authRequired: false },
  },
  {
    // 다날 화면
    path: "/user/DanalAuth",
    name: "DanalAuth",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/cmmn/DanalAuth"),
    meta: { authRequired: false },
  },
  {
    // 회원가입 시 -> 거래 비밀번호 설정
    path: "/user/join/createTradePw",
    name: "JoinCreateTradePw",
    // name: "IntegrateCreatAccount",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-02-C"),
    meta: { authRequired: false },
  },
  // -------------------------------------------------
  {
    // 통합 회원 비밀번호 재설정
    path: "/user/integrated/updatePw",
    name: "IntegratedUpdatePw",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/M-b-02-C-3"),
    meta: { authRequired: false },
  },
  // --------------------------------------------------
  // 마이페이지
  {
    // 개인
    path: "/user/myPg",
    redirect: "/user/myPg/personal/info",
  },
  {
    path: "/user/myPg/personal",
    redirect: "/user/myPg/personal/info",
  },
  {
    // 개인 - 회원 정보
    path: "/user/myPg/personal",
    name: "MemInfo",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/F-a-01"),
    meta: { authRequired: true },
    children: [
      {
        path: "info",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-a-01-C.vue"
          ),
      },
      {
        path: "point",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-b-02-R.vue"
          ),
      },
      {
        path: "gallery",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-c-T03-R.vue"
          ),
        meta: { authRequired: false },
      },
      {
        path: "inquiry",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-d-T04-R.vue"
          ),
        meta: { authRequired: false },
      },
      {
        path: "inquiry/:nttNo",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-d-T04-D.vue"
          ),
      },
    ],
  },
  {
    // 기업 - 회원 정보
    path: "/user/myPg/business",
    redirect: "/user/myPg/business/info",
  },
  {
    path: "/user/myPg/business",
    name: "BusniMemInfo",
    component: () =>
      import(/* webpackChunkName: "User" */ "@/components/member/F-a-02"),
    meta: { authRequired: true },
    children: [
      {
        path: "info",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-a-02-C.vue"
          ),
      },
      {
        path: "point",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-b-02-R.vue"
          ),
      },
      {
        path: "gallery",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-c-T03-R.vue"
          ),
        meta: { authRequired: false },
      },
      {
        path: "inquiry",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-d-T04-R.vue"
          ),
        meta: { authRequired: false },
      },
      {
        path: "inquiry/:nttNo",
        component: () =>
          import(
            /* webpackChunkName: "User" */ "../components/member/F-d-T04-D.vue"
          ),
      },
    ],
  },
  // --------------------------------------------------
  // router Active 사용 path
  {
    path: "/art",
    redirect: "/art/intro",
  },
  {
    path: "/art/intro",
    name: "ArtIntro",
    component: () =>
      import(/* webpackChunkName: "Art" */ "@/components/art/A-a-D"),
    meta: { authRequired: false },
  },
  {
    path: "/art/notice/detail",
    name: "NttOne",
    component: () =>
      import(/* webpackChunkName: "Art" */ "@/components/art/A-b-D"),
    meta: { authRequired: false },
  },
  {
    path: "/art/notice",
    name: "Ntt",
    component: () =>
      import(/* webpackChunkName: "Art" */ "@/components/art/A-b-M"),
    meta: { authRequired: false },
  },
  // -----------------------------------------------------------------------
  {
    path: "/art/event",
    redirect: "/art/event/playing",
  },
  {
    path: "/art/event",
    name: "Event",
    component: () =>
      import(/* webpackChunkName: "Art" */ "@/components/art/A-c"),
    meta: { authRequired: false },
    children: [
      {
        path: "playing",
        component: () =>
          import(/* webpackChunkName: "Art" */ "@/components/art/A-c-T01-R"),
      },
      {
        path: "playing/:nttNo",
        props: { etGbn: "Pl" },
        component: () =>
          import(/* webpackChunkName: "Art" */ "@/components/art/A-c-D"),
      },
      {
        path: "passDeadline",
        component: () =>
          import(/* webpackChunkName: "Art" */ "@/components/art/A-c-T02-R"),
      },
      {
        path: "passDeadline/:nttNo",
        props: { etGbn: "Pa" },
        component: () =>
          import(/* webpackChunkName: "Art" */ "@/components/art/A-c-D"),
      },
    ],
  },
  // -----------------------------------------------------------------------
  {
    path: "/art/support",
    redirect: "/art/support/FAQ",
  },
  {
    path: "/art/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "Art" */ "@/components/art/A-d"),
    meta: { authRequired: false },
    children: [
      {
        path: "FAQ",
        component: () =>
          import(/* webpackChunkName: "Art" */ "@/components/art/A-d-T01"),
      },
      {
        path: "inquiry",
        component: () =>
          import(/* webpackChunkName: "Art" */ "@/components/art/A-d-T02"),
        meta: { authRequired: true },
      },
    ],
  },
  // --------------------------------------------------
  {
    path: "/exibition",
    redirect: "/exibition/kimwan",
  },
  {
    path: "/exibition/:id",
    component: () =>
      import(/* webpackChunkName: "ext" */ "@/components/error/ExibitionView"),
  },
  // --------------------------------------------------
  // router Active 사용 path
  {
    path: "/trade",
    redirect: () => {
      const symbolCd = getDefaultSymbolCd();
      return {
        path: `/trade/mtsDemo1/${symbolCd}`,
      };
    },
  },
  {
    path: "/trade/mtsDemo1",
    redirect: () => {
      const symbolCd = getDefaultSymbolCd();
      return {
        path: `/trade/mtsDemo1/${symbolCd}`,
      };
    },
  },
  {
    path: "/trade/mtsDemo1/:symbolCd",
    name: "MtsDemo1",
    component: () =>
      import(
        /* webpackChunkName: "Trade" */ "../components/trade/MtsDemo1View"
      ),
    meta: { authRequired: false },
  },
  {
    path: "/trade/order",
    redirect: () => {
      const symbolCd = getDefaultSymbolCd();
      return {
        path: `/trade/order/${symbolCd}`,
      };
    },
  },
  {
    path: "/trade/order/:symbolCd",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "Trade" */ "../components/trade/OrderView"),
    meta: { authRequired: true },
  },
  {
    path: "/trade/assets",
    name: "Assets",
    component: () =>
      import(/* webpackChunkName: "Trade" */ "../components/trade/Assets"),
    meta: { authRequired: true },
  },
  {
    path: "/trade/FavoriteArts",
    beforeEnter: (to, from, next) => {
      getAccessToken().then((res) => {
        if (!res || !hasRole("ROLE_USER")) {
          next({ path: "/trade/FavoriteArts/2" });
        } else {
          next({ path: "/trade/FavoriteArts/1" });
        }
      });
    },
  },
  {
    path: "/trade/FavoriteArts/:tabCd",
    name: "FavoriteArts",
    component: () =>
      import(/* webpackChunkName: "Trade" */ "../components/trade/C-B-T00-M"),
    meta: { authRequired: false },
  },
  // --------------------------------------------------
  // 공모전
  {
    path: "/pssrp",
    redirect: "/pssrp/pssrpList/a/a/a",
  },
  {
    path: "/pssrp/pssrpList",
    redirect: "/pssrp/pssrpList/a/a/a",
  },
  {
    path: "/pssrp/pssrpList/:category/:classCd/:status",
    name: "Pssrp",
    component: () =>
      import(/* webpackChunkName: "Pssrp" */ "../components/pssrp/B-R-01-M"),
    meta: { authRequired: false },
    // children: [
    //   {
    //     path: "/detail/:symbolCd",
    //     name: "PssrpDetail",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "Pssrp" */ "@/components/pssrp/B-R-01-D"
    //       ),
    //   },
    // ]
  },
  {
    path: "/pssrp/pssrpList/:category/:classCd/:status/detail/:symbolCd",
    name: "PssrpDetail",
    component: () =>
      import(/* webpackChunkName: "Pssrp" */ "@/components/pssrp/B-R-01-D-M"),
    meta: { authRequired: false },
  },
  // --------------------------------------------------
  // router Active 사용 path
  {
    path: "/artMedia",
    redirect: "/artMedia/newspaper",
  },
  {
    path: "/artMedia/artpedia",
    redirect: "/artMedia/artpedia/artpedia",
  },
  {
    path: "/artMedia/artpedia",
    name: "ArtMediaList",
    component: () =>
      import(/* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-c"),
    meta: { authRequired: false },
    children: [
      {
        path: "artpedia",
        props: { atGbn: "A" },
        component: () =>
          import(
            /* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-c-R.vue"
          ),
      },
      {
        path: "artTech",
        props: { atGbn: "T" },
        component: () =>
          import(
            /* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-c-R.vue"
          ),
      },
      {
        path: "artpedia/:nttNo",
        name: "ArtpediaDetail",
        props: { atGbn: "A" },
        component: () =>
          import(
            /* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-c-D"
          ),
        meta: { authRequired: false },
      },
      {
        path: "artTech/:nttNo",
        name: "ArtTechDetail",
        props: { atGbn: "T" },
        component: () =>
          import(
            /* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-c-D"
          ),
        meta: { authRequired: false },
      },
    ],
  },
  // --------------------------------------------------
  {
    path: "/artMedia/newspaper",
    name: "Newspaper",
    component: () =>
      import(
        /* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-a-T01-R.vue"
      ),
    meta: { authRequired: false },
  },
  {
    path: "/artMedia/video",
    name: "Video",
    component: () =>
      import(
        /* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-a-T02-R.vue"
      ),
    meta: { authRequired: false },
  },
  {
    path: "/artMedia/video/:nttNo",
    name: "VideoDetails",
    props: { nttNo: "18" },
    component: () =>
      import(
        /* webpackChunkName: "ArtMedia" */ "../components/artMedia/E-a-D.vue"
      ),
  },
  // --------------------------------------------------
  {
    path: "/footer/policy1",
    name: "FooterPolicy1",
    component: () =>
      import(
        /* webpackChunkName: "Policy" */ "../components/main/FooterPolicy1"
      ),
    meta: { authRequired: false },
  },
  {
    path: "/footer/policy2",
    name: "FooterPolicy2",
    component: () =>
      import(
        /* webpackChunkName: "Policy" */ "../components/main/FooterPolicy2"
      ),
    meta: { authRequired: false },
  },
  // --------------------------------------------------
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "Common" */ "../components/error/404View"),
  },
  {
    path: "/error/:code",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "Common" */ "../components/error/ErrorView"),
  },
  {
    path: "/landing",
    name: "Landing",
    component: () =>
      import(
        /* webpackChunkName: "Common" */ "../components/error/LandingView"
      ),
  },
  {
    path: "/empty",
    name: "Empty",
    component: () =>
      import(
        /* webpackChunkName: "Common" */ "../components/temporaryView/BeingReadyPage"
      ),
  },
];
// ----------------------------------------------------------------------------------
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // vue의 스크롤 저장 기능을 이용. 페이지 기본 스크롤 위치를 변경한다

    /*
1. 뒤로 가기 등 이전에 접근한 적 있는 페이지는 스크롤 상태 유지.
2. hash 가 있다면 문서 중 해당 id 로 이동 
 - 같은 페이지에서 hash 만 바뀌면 부드럽게 스크롤
 - 다른 페이지로 이동한다면 바로 hash 위치 표시
3. 최초 진입시에는 최상위로 이동
    */

    // 뒤로 가기 등 이전에 접근한 적 있는 페이지는 스크롤 상태 유지.
    if (savedPosition) {
      return savedPosition;
      // hash 가 있다면 문서 중 해당 id 로 이동
    } else if (to.hash) {
      // 같은 페이지에서 hash를 사용하여 이동할 때에는 부드럽게 스크롤
      return {
        selector: to.hash,
        behavior: to.path === from.path ? "smooth" : "auto",
      };
    }
    // 최초 진입시에는 최상위로 이동
    return { x: 0, y: 0 };
  },
});

window.router = router;
// 라우터 이동전 전처리 전역설정 - 로그인 필수 여부 체크

router.beforeEach((to, from, next) => {
  // TODO: 로그인 정보 갱신

  // 로그인 페이지로 이동한다면 이전 페이지 정보를 기억하고 있다가 로그인 후 다시 이동시켜준다(to로 경로 전달)
  if (
    // 로그인 필요한 페이지인지 확인
    to.matched.some((routeInfo) => {
      return routeInfo.meta.authRequired;
    })
  ) {
    getAccessToken().then((res) => {
      if (!res || !hasRole("ROLE_USER")) {
        next({ path: `/error/login?url=${to.fullPath}`, replace: false });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
