$(function () {
  //북마크
  bookmark();

  //작품매매 화면 레이아웃텝
  pc_tab();
  // m_tab();

  //작품매매 카테고리
  category_btn();

  m_conts_btn();
  m_pop_control();

  //공모전 좋아요 버튼
  like_btn();

  //모바일 메뉴 열고 닫기
  // $(".m_btn_menu").on("click", function () {
  // $("html").addClass("pop");
  // $('html').attr('style','overflow: hidden;');
  // $(".m_gnb").addClass("open");
  // $(".dim").addClass("active");
  // });
  // $(".m_gnb_closed_btn").on("click", function () {
  //   $("html").removeClass("pop");
  //   //$('html').attr('style','');
  //   $(".m_gnb").removeClass("open");
  //   $(".dim").removeClass("active");
  // });
  // $(".m_gnb_menu .one_depth > li > a").on("click", function () {
  //   $('.m_gnb_menu .one_depth > li > a').not($(this).toggleClass('active')).removeClass('active');
  // $("div.two_depth").parent('div').not($(this).next("div").slideToggle(500)).slideUp();
  // });

  //약관동의 체크박스 전체선택
  /*
    $('.all_chk_area .checkbox02 input').on('change',function(){
        $(this).parents('.all_chk_area').toggleClass('on');
        var checked = $(this).is(':checked');
        if(checked){
            $('.chk_list_area input').prop('checked',true);
        }else if(!checked){
            $('.chk_list_area input').prop('checked',false);
        }

    });
   
    //약관동의 체크박스 선택
    $('.chk_list_area input').on('change',function(){
        var checkednum01 = $('.chk_list_area input').length;
        var checkednum02 = $('.chk_list_area input:checked').length;
        if(checkednum01 != checkednum02){
            $('.all_chk_area').removeClass('on');
            $('.all_chk_area input').prop('checked',false);
        }else if(checkednum01 == checkednum02){
            $('.all_chk_area').addClass('on');
            $('.all_chk_area input').prop('checked',true);
        }
    });
     */

  //북마크
  function bookmark() {
    $('[class^="bookmark"]').on("click", function () {
      $(this).toggleClass("active");
    });
  }

  //작품매매 화면 레이아웃텝
  function pc_tab() {
    $(".pc_tab_btn").on("click", function () {
      if ($(this).parents(".pc_btn").hasClass("pc_btn02") == true) {
        // $('.pc_tab_btn').removeClass("active");

        $(this).parents(".pc_btn").find(".pc_tab_btn").removeClass("active");
        $(this).addClass("active");

        var target = $(this).attr("data-tab");
        $('div.box05 div[class*="sub_box"]').removeClass("active");
        $("div." + target + "").addClass("active");
      } else {
        //$('.pc_tab_btn').removeClass("active");
        $(this).parents(".pc_btn").find(".pc_tab_btn").removeClass("active");
        $(this).addClass("active");
        var target = $(this).attr("data-tab");
        $('div[class*="pc_tab"]').removeClass("active");
        $("div." + target + "").addClass("active");
      }
    });
  }

  // function m_tab() {
  //   $(".m_tab_btn").on("click", function () {
  //     console.log($(this).parents(".m_btn").hasClass("m_btn02"));
  //     if ($(this).parents(".m_btn").hasClass("m_btn02") == true) {
  //       var target = $(this).attr("data-tab");
  //       $('div[class*="sub_box"]').removeClass("active");
  //       if (target != "sub_box04") {
  //         $("div.sub_box00").addClass("active");
  //       }
  //       $("div." + target + "").addClass("active");
  //     } else {
  //       var target = $(this).attr("data-tab");
  //       $('div[class*="m_tab"]').removeClass("active");
  //       $("div." + target + "").addClass("active");
  //     }
  //   });
  // }

  function category_btn() {
    $(".category_area > div > button").on("click", function () {
      $(this).toggleClass("active");
    });
    $(".category_area > button.btn_fold").on("click", function () {
      $(this).prev("div").toggleClass("active");
    });
  }

  function m_conts_btn() {
    $(".m_btn ul li button").on("click", function () {
      $(".m_btn ul li button").removeClass("active");
      $(this).addClass("active");
    });
  }

  function m_pop_control() {
    $("button.m_pop_closed").on("click", function () {
      $(".m_pop").removeClass("active");
      //$('html').attr('style','');
      $("html").removeClass("pop");
    });

    $(".m_search_area div").on("click", function () {
      $(".m_pop").addClass("active");
      $(".m_pop div.search_box input").focus();
      // $('html').attr('style','overflow: hidden;');
      $("html").addClass("pop");
    });
  }

  //비밀번호
  var placeTxt = "비밀번호";
  if ($(".password_box input").val() != "") {
    $(".password_box").addClass("active");
  } else {
    $(".password_box").removeClass("active");
  }
  $(".password_box input").on("focus focusout", function () {
    if ($(this).val() == "") {
      $(this).on("keyup", function () {
        $(this).attr("placeholder", "");
        $(this).parent().addClass("active");
        if ($(this).val() == "") {
          $(this).parent().removeClass("active");
          $(this).attr("placeholder", placeTxt);
        }
      });
    } else {
      $(this).parent().addClass("active");
    }
  });

  $(".inputbox input").on("focus focusout", function () {
    $(this).parents().addClass("on");
    console.log($(this).val());
    if ($(this).val() != "") {
      $(this).parents().addClass("on");
    } else {
      $(this).parents().removeClass("on");
    }
  });

  //테이블 내부 체결,차트 버튼
  $('button[class*="tlb_btn"]').on("click", function () {
    var btnType = $(this).attr("class").substr(0, 9);
    $('button[class*="tlb_btn"]').removeClass("active");
    $(this).addClass("active");
    if (btnType == "tlb_btn01") {
      $("td.tlb_td02").removeClass("active");
      $("td.tlb_td01").addClass("active");
    } else if (btnType == "tlb_btn02") {
      $("td.tlb_td01").removeClass("active");
      $("td.tlb_td02").addClass("active");
    }
  });

  //리사이징시 메뉴 및 dim처리 해제
  $(window).resize(function () {
    $("html").removeClass("pop");
    $(".m_gnb").removeClass("open");
    $(".two_depth").attr("style", "");
    $(".dim").removeClass("active");
  });

  //공모전 좋아요 버튼
  function like_btn() {
    $(".btn_like").on("click", function () {
      $(this).toggleClass("active");
    });
  }
});
