import { mapGetters } from "vuex";

/**
 * 앱 연동. 기기 정보 관련 mixin. 필요 컴포넌트에 등록하고 사용한다.
 *
 * 1. 회원 아이디 유무에 따라 푸시 수신할 기기 등록
 */
export default {
  computed: {
    ...mapGetters("login", ["getLoginMember"]),
  },
  data() {
    return {
      deviceInfo: null,
    };
  },
  methods: {
    /**
     * 푸시 수신여부를 갖고 있는 기기 정보를 불러온다. 변경하면 업데이트를 수행한다
     * */
    async getDeviceInfo() {
      const { mbrId } = this.getLoginMember;
      const deviceType = this.$flutterBridge.deviceType;
      return new Promise((resolve, reject) => {
        this.$flutterBridge.getUuid({}, ({ uuid }) => {
          this.$api
            .call("/api/device/info", {
              uuid,
              deviceType,
              mbrId,
            })
            .then((res) => resolve(res))
            .catch((error) => reject(error));
        });
      });
    },
    /**
     * uuid+mbrId 로 구분 가능하게 기기 정보를 등록(한 사람 여러기기, 한 기기 여러사람 가능)
     *
     * @param {string} mbrId null로 설정가능. 이 경우 로그인 하지 않은 기기 푸시를 보낼 수 있음
     * @param {boolean} rcvYn 푸시 수신 여부. 지정하지 않으면 이전 등록한 값을 유지한다
     * @returns
     */
    saveDeviceInfo(mbrId, rcvYn) {
      const deviceType = this.$flutterBridge.deviceType;
      if (deviceType === "WEB") {
        // 웹브라우저는 푸시 등록 하지 않음. 앞으로 서비스워커로 브라우저 푸시 수신 가능하게 만들면 없애도 됨
        return;
      }

      this.$flutterBridge.getUuid({}, ({ uuid }) => {
        // PUSH 수신 native prompt 이후 token 획득.
        this.$flutterBridge.getFcmToken(null, ({ token: fcmToken }) => {
          this.$api
            .call("/api/device/create", {
              uuid,
              fcmToken,
              deviceType,
              rcvYn: rcvYn !== undefined && rcvYn !== null ? rcvYn : null, // null로 하면 사용자 설정 유지
              mbrId,
            })
            .then();
        });
      });
    },
  },
};
