import Vue from "vue";

var isFlutter;

/** 플러그인 초기화 여부 */
var platformReady = false;

/** 플러그인 초기하 전에 실행 요청 온 목록. 초기화 이벤트 끝난 후에 실행한다 */
const callBeforeReadyList = [];

/** WEB, IOS, AOS */
var deviceType = "WEB";

/**
 * 네이티브 플러그인 작동 메서드 호출. 준비되지 않았다면
 *
 * @param {string} method 메서드 명
 * @param {any} params 전달할 패러미터
 * @param {function} callback 콜백
 * */
function callMethod(method, params, callback) {
  if (isFlutter === false) {
    return;
  }

  // 플러그인 초기화 전이면 목록에 넣고, 초기화 이후에 수행할 수 있도록 한다
  if (!platformReady) {
    callBeforeReadyList.push({
      method,
      params,
      callback,
    });
    return;
  }

  if (typeof callback === "function") {
    // callback 이 있다면 응답 값 처리 리스너 등록
    const callbackId = `${Math.random()}`;
    console.debug(`inAppWebview method ${method}, Start`);
    window.flutter_inappwebview
      .callHandler(method, params, callbackId)
      .then((result) => {
        callback(result);
      });
  } else {
    // callback 이 없다면 실행하고 끝
    window.flutter_webview_plugin.callHandler(method, params);
  }
}

function initBridge() {
  window.addEventListener("flutterInAppWebViewPlatformReady", function () {
    platformReady = true;
    // console.debug(`inAppWebview method ${method}, Start`);

    // 초기화 이전에 들어온 요청 수행
    if (callBeforeReadyList?.length > 0) {
      for (let i = 0; i < callBeforeReadyList.length; i++) {
        const { method, params, callback } = callBeforeReadyList[i];
        callMethod(method, params, callback);
      }
    }
  });
}
/** flutter inappwebview 메서드 호출 및 콜백 */
const plugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    // useragent 에 flutter 가 포함되어 있을 때에만 실행. $flutterBridge 가 없으면 모든 결과는 null 이 됨
    if (navigator.userAgent.indexOf("Flutter") >= 0) {
      isFlutter = true;

      // flutter 이면 iOS, Aos 체크
      if (navigator.userAgent.indexOf("iOS") >= 0) {
        deviceType = "IOS";
      } else {
        deviceType = "AOS";
      }
    } else {
      isFlutter = false;
    }

    initBridge();

    Vue.prototype.$flutterBridge = {
      /** fcm 등록 후 토큰 취득 */
      getFcmToken: (params, callback) => {
        callMethod("getFcmToken", params, callback);
      },
      /**
       * 기기 고유 아이디 취득. 회원별 기기별 설정 관리에 사용
       *
       * @param params flutter 에 보내는 args : 현재 flutter 내에서 사용하지 않음
       * @param callback uuid 취득후 동작할 callback method return 은 {uuid: String} 형식
       */
      getUuid: (params, callback) => {
        callMethod("getUuid", params, callback);
      },
      /**
       * 푸시 메시지를 눌러 실행했다면 후속 동작 처리 /
       * 앱 켜진상태에서 메시지 받았을 때 처리
       * */
      handleFcmMessage: (params, callback) => {
        // 푸시 메시지를 눌러 실행했다면 후속 동작 처리
        callMethod("getStartingFcmMessage", params, callback);

        // 앱 켜진상태에서 메시지 받았을 때 처리
        window.addEventListener(
          "subscribeFcmMessage",
          (event) => {
            callback(event);
          },
          false
        );
      },
      /**
       * 푸시 메시지를 눌러 실행했다면 후속 동작 처리 /
       * 앱 켜진상태에서 메시지 받았을 때 처리
       * */
      handleDynamicLink: (params, callback) => {
        // 푸시 메시지를 눌러 실행했다면 후속 동작 처리
        callMethod("getStartingDynamicLink", params, callback);

        // 앱 켜진상태에서 메시지 받았을 때 처리
        window.addEventListener(
          "subscribeDynamicLink",
          (event) => {
            console.debug(event);
            callback(event);
          },
          false
        );
      },
      isFlutter,
      deviceType,
    };
  },
};

Vue.use(plugin);
export default plugin;
