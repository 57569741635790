/* eslint-disable no-unused-vars */

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true, // 모듈이 독립적으로 재사용

  state: {
    users: [],
    user: {
      zipCd: "",
      address1: "",
      address2: "",
      mbrId: "",
      mbrPw: "",
      mbrPw2: "",
      nickNm: "",
      mbrNm: "",
      celPhoneNo: "",
      sex: "",
      birthDate: "",
      email: "",
      recommender: "",
      policyMbr0001Yn: "Y",
      policyMbr0002Yn: "Y",
      policyMbr0003Yn: "Y",
      policyMbr0004Yn: "Y",
      ci: "",
    },
    memberAccount: {
      accountNo: "",
      mbrId: "",
      accountPwd: "",
      vrtlAccountNo: "",
      accountStatusNo: "",
    },
    pointInfo: {
      mbrId: "",
      accountNo: "",
      point: "",
      pointAble: "",
    },
    loginStatus: {
      status: "",
    },
    count: 0, // count 를 0 으로 초기화
    checkId: "",
    checkNickNm: "",
    authIdCheck: "",

    policyAgree: {
      policyId: "",
      policyTitle: "",
      policyContext: "",
    },
  },

  getters: {
    getUser(state) {
      // 유저 정보
      return state.user;
    },
    getUserByIdx(state, idx) {
      // 유저 index
      return state.users[idx];
    },
    getMemberAccount: (state) => {
      // 유저계좌 정보
      return state.memberAccount;
    },
    getUserIdentify(state) {
      // 유저 이름 [유저 id]
      let u = state.user;
      return u.name + "[" + u.id + "]";
    },
    getUserIdentifyByIdx(state, idx) {
      // 유저 index 로 찾는 => 유저 이름 [유저 id]
      let u = state.users[idx];
      return u.name + "[" + u.id + "]";
    },
    getCheckId: function (state) {
      return state.checkId;
    },
    getCheckNickNm: function (state) {
      return state.checkNickNm;
    },

    getAccessToken: function (state) {
      return state.token.accessToken;
    },
    isAuthenticated: function (state) {
      return state.isAuthenticated;
    },
    getPolicyAgree: function (state) {
      return state.isAuthenticated;
    },
    getPointInfo: function (state) {
      return state.pointInfo;
    },
  },

  mutations: {
    assignUser(state, user) {
      Object.assign(state.user, user);
    },
    setCheckDuplicate(state, checkId) {
      state.checkId = checkId;
    },
    setCheckDuplicateNick(state, checkNickNm) {
      state.checkNickNm = checkNickNm;
    },
    setMemberAccount(state, memberAccount) {
      state.memberAccount = memberAccount;
    },
    // setter
    setUsers(state, users) {
      state.users = users;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserId(state, value) {
      if (value.target && value.target.value) {
        // 중복 체크 ???
        state.user.id = value.target.value;
        return;
      }
      state.user.id = value;
    },
    setUserPasswd(state, value) {
      if (value.target && value.target.value) {
        state.user.passwd = value.target.value;
        return;
      }
      state.user.passwd = value;
    },
    setPolicyAgree(state, policyAgree) {
      state.policyAgree = policyAgree;
    },
    addUser(state, user) {
      state.users.push(user);
    },
    setPolicy(state, value) {
      if (value.policyMbr0001Yn === true) value.policyMbr0001Yn = "Y";
      else value.policyMbr0001Yn = "N";
      if (value.policyMbr0002Yn === true) value.policyMbr0002Yn = "Y";
      else value.policyMbr0002Yn = "N";
      state.user.policyMbr0001Yn = value.policyMbr0001Yn;
      state.user.policyMbr0002Yn = value.policyMbr0002Yn;
      if (value.policyMbr0003Yn === true) value.policyMbr0003Yn = "Y";
      else value.policyMbr0003Yn = "N";
      if (value.policyMbr0004Yn === true) value.policyMbr0004Yn = "Y";
      else value.policyMbr0004Yn = "N";

      state.user.policyMbr0003Yn = value.policyMbr0003Yn;
      state.user.policyMbr0004Yn = value.policyMbr0004Yn;
    },
    setPointInfo(state, pointInfo) {
      state.pointInfo = pointInfo;
    },

    logout(state, payload = {}) {
      state.token.accessToken = "";
      state.isAuthenticated = false;
    },
    login(state, user, payload = {}) {
      Object.assign(state.user, user);
      state.token.accessToken = payload.accessToken;
      state.isAuthenticated = true;
    },
  },

  actions: {
    fetchUser({ commit }, params) {
      window
        .axios({
          url: params.url,
          data: params.data,
        })
        .then((res) => {
          console.log(res);
          if (params.postFnc) params.postFnc(res);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else {
            console.log("Error", error.message);
          }
        });
    },
    loginUser: function (context, params, commit) {
      window
        .axios({
          method: "POST",
          url: "/api/user/loginExec",
          data: {
            id: params.id,
            passwd: params.passwd,
            isAutoExtendYn: params.isAutoExtendYn,
          },
        })
        .then((res) => {
          const { data } = res;
          const urlCode = data.input.urlCode;

          context.commit("login", res.data.output, {
            accessToken: data.accessToken,
          });

          if (urlCode == 0) {
            //로그인성공
            window.router.push("/");
          } else if (urlCode == 1) {
            //가상계좌 개설로 이동
            window
              .axios({
                method: "POST",
                url: "/api/member/selectVrtlAccount",
                data: {
                  id: params.id,
                  passwd: params.passwd,
                  isAutoExtendYn: params.isAutoExtendYn,
                },
              })
              .then((res) => {
                commit("setMemberAccount", res.data.output);
                window.router.push("/user/join/createTradePw");
              })
              .catch((error) => {
                console.error(error);
              });
          } else if (urlCode == 2) {
            //회원통합 페이지 이동
            window.router.push("/");
          } else {
            window.router.push("/user/login");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    joinUser({ commit, state }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/joinMember",
          data: params,
        })
        .then((res) => {
          console.log(res);
          if (res.data.output === 0) {
            window.router.push({
              name: "JoinCreateTradePw",
              params: { data: params },
            });
          } else if (res.data.output === 1) {
            alert("정보를 저장하는데 실패하였습니다.");
            window.router.push("/user/join");
          } else if (res.data.output === 2) {
            alert("가상계좌 발급불가 상태입니다. 관리자에게 문의해주세요.");
            window.router.push("/");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkJoinMember({ commit }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/searchMemberId",
          data: params,
        })
        .then((res) => {
          console.log("res.data.output : :" + res.data.output);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkDuplicateId({ commit, state }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/checkDuplicateId",
          data: { mbrId: params.mbrId },
        })
        .then((res) => {
          commit("setCheckDuplicate", res.data.output);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkDuplicateNickNm({ commit, state }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/checkDuplicateNick",
          data: { nickNm: params.nickNm },
        })
        .then((res) => {
          commit("setCheckDuplicateNick", res.data.output);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    insertMemberAccount({ commit, state }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/insertMemberAccount",
          data: params,
        })
        .then((res) => {
          console.log(res.data.output);
          window.router.push(
            "/user/join/success?memberCorpYn=" + params.user.memberCorpYn
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    insertMemberAccountPw({ commit, state }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/insertMemberAccountPw",
          data: params,
        })
        .then((res) => {
          console.log(res.data.output);
          if (res.data.output == 1) {
            alert("가상계좌 패스워드 설정이 완료되었습니다.");
          } else {
            alert("가상계좌 패스워드 설정이 실패되었습니다.");
          }
          window.router.push("/");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setDanalData({ commit, state }, params) {
      commit("setUser", params);
      window.router.push("/user/join/personal/signupAgre");
    },
    savePolicy({ commit }, params) {
      commit("setPolicy", params);
    },
    saveSighUpGbn({ commit }, params) {
      commit("setSighUpGbn", params);
    },
    findId({ commit }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/searchMemberId",
          data: {
            mbrNm: params.mbrNm,
            celPhoneNo: params.celPhoneNo,
            ci: params.ci,
          },
        })
        .then((res) => {
          if (res.data.output != null && res.data.output != "") {
            commit("setUsers", res.data.output);
            window.router.push("/user/findId/result");
          } else {
            alert("등록된 계정이 없습니다.");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    findPw({ commit }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/searchMemberPw",
          data: params,
        })
        .then((res) => {
          if (res.data.output != null) {
            window.router.push("/user/findPw/update");
          } else {
            alert("입력하신 아이디로 등록된 계정이 없습니다.");
            //window.router.push("/user/findPw");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updatePw({ commit }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/updateMemberPw",
          data: params,
        })
        .then((res) => {
          alert("비밀번호가 변경되었습니다.");
          window.router.push("/");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    reUpdatePw({ commit }, params) {
      //초기화된 패스워드 수정
      window
        .axios({
          method: "POST",
          url: "/api/member/reUpdateMemberPw",
          data: params,
        })
        .then((res) => {
          alert("비밀번호가 재설정되었습니다.");
          window.router.push("/user/login");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async authMemberCheck({ commit, state }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/member/authMemberCheck",
          data: params,
        })
        .then((res) => {
          return res.data.output != 0;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async joinMemberCheck({ commit, state }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/member/joinMemberCheck",
          data: params,
        })
        .then((res) => {
          return res.data.output != 0;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async selectVrtlAccountPop({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/member/selectVrtlAccount",
          data: {
            mbrId: params,
          },
        })
        .then((res) => {
          /*nav*/
          commit("setMemberAccount", res.data.output);
          // return res.data.output;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateIntegMember({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/member/updateIntegMember",
          data: params,
        })
        .then((res) => {
          if (res.data.output === 2) {
            //this.$router.go();
            alert("통합아이디 설정이 완료되었습니다.");
          } else {
            alert("정보를 저장하는데 실패하였습니다.");
            //this.$router.go();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getPolicyAgree({ commit }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/member/selectPolicyPopup",
          data: {
            policyId: params,
          },
        })
        .then((res) => {
          /*nav*/
          commit("setPolicyAgree", res.data.output);
          // return res.data.output;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async memberInfo({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/selectMemberInfo",
          data: {
            mbrId: params,
          },
        })
        .then((res) => {
          /*nav*/
          commit("setUser", res.data.output);
          // return res.data.output;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    accountInfo({ commit }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/mypage/selectAccountInfo",
          data: {
            mbrId: params,
          },
        })
        .then((res) => {
          /*nav*/
          commit("setMemberAccount", res.data.output);
          // return res.data.output;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async checkNickNmDuplicated({ commit }, params) {
      const { nickNm } = params;
      return window
        .axios({
          url: "/api/member/checkDuplicateNick",
          method: "POST",
          data: {
            nickNm: params.nickNm,
            mbrId: params.mbrId,
          },
          isSilent: true,
        })
        .then((res) => {
          // 결과 가공 후 true / false 리턴
          return res.data.output === "N";
        });
      // return nickNm !== "테스트4";
    },
    async updatePhoneNo({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/updatePhoneNo",
          data: params,
        })
        .then((res) => {
          /*nav*/
          if (res.data.output == 0) {
            alert("등록정보와 맞지 않습니다.");
          } else {
            alert("핸드폰번호가 변경되었습니다.");
          }
        })
        .catch((error) => {
          alert("핸드폰번호 변경이 실패하였습니다.");
        });
    },
    async selectAuthMemberCheck({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/selectAuthMemberCheck",
          data: params,
        })
        .then((res) => {
          /*nav*/
          if (res.data.output == 1) {
            alert("본인인증이 완료되었습니다.");
          } else {
            alert("등록정보와 맞지 않습니다.");
          }
          return res.data.output == 1;
        })
        .catch((error) => {
          alert("본인인증에 실패하였습니다.");
        });
    },
    async updateAccountPwd({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/updateAccountPwd",
          data: params,
        })
        .then((res) => {
          /*nav*/
          if (res.data.output == 1) {
            alert("계좌비밀번호가 변경되었습니다.");
          } else {
            alert("계좌비밀번호 변경에 실패하였습니다.");
          }
          return res.data.output == 1;
        })
        .catch((error) => {
          alert("계좌비밀번호 변경에 실패하였습니다.");
        });
    },
    async memberPwdCheck({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/memberPwdCheck",
          data: params,
        })
        .then((res) => {
          /*nav*/
          return res.data.output;
        })
        .catch((error) => {
          alert("서버에서 정보를 불러오는데 실패하였습니다.");
        });
    },
    async updateMemberInfo({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/updateMemberInfo",
          data: params,
        })
        .then((res) => {
          return res.data.output;
        })
        .catch((error) => {
          alert("서버에서 정보를 불러오는데 실패하였습니다.");
        });
    },
    async deleteMemberInfo({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/deleteMemberInfo",
          data: params,
        })
        .then((res) => {
          return res.data.output;
        })
        .catch((error) => {
          alert("서버에서 정보를 불러오는데 실패하였습니다.");
        });
    },
    myPointInfo({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/selectPointInfo",
          data: params,
        })
        .then((res) => {
          return res.data.output;
        })
        .catch((error) => {
          alert("서버에서 정보를 불러오는데 실패하였습니다.");
        });
    },
    myPointHistory({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/selectPointHistory",
          data: params,
        })
        .then((res) => {
          return res.data.output;
        })
        .catch((error) => {
          alert("서버에서 정보를 불러오는데 실패하였습니다.");
        });
    },
    myGalleryList({ commit }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/mypage/selectMyGalleryList",
          data: params,
        })
        .then((res) => {
          return res.data.output;
        })
        .catch((error) => {
          alert("서버에서 정보를 불러오는데 실패하였습니다.");
        });
    },
    //관심작품 클릭
    async mergeFavor({ commit, state }, params) {
      if (params.mbrId == null) {
        return;
      }
      return window
        .axios({
          method: "POST",
          url: "/api/art/mergeFavorite",
          data: {
            symbolCd: params.symbolCd,
            mbrId: params.mbrId,
          },
        })
        .then((res) => {
          return res.data.output;
        })
        .catch((error) => {
          console.log("mergeFavor err : " + JSON.stringify(error));
        });
    },

    menuCheck({ commit, state }, params) {
      window
        .axios({
          method: "GET",
          url: "/api/menuCheck",
          params: { menu: params.path },
        })
        .then((res) => {
          params.callback(res.data.code);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logout({ context, payload }) {
      let params = {
        id: payload.id,
        passwd: payload.passwd,
      };
      return new Promise((resolve) => {
        setTimeout(function () {
          context.commit("logout", payload);
          resolve({});
        }, 1000);
      });
    },
  },
};
