/* eslint-disable no-unused-vars */

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    /** 파라미터 mbrId 중복 확인 */
    async checkMbrIdDuplicated({ commit }, params) {
      const { mbrId } = params;
      return window
        .axios({
          url: "/api/member/checkDuplicateId",
          method: "POST",
          data: {
            mbrId,
          },
          isSilent: true,
        })
        .then((res) => {
          // 결과 가공 후 true / false 리턴
          return res.data.output === "N";
        });
      // return mbrId !== "test4";
    },
    /** 파라미터 nickNm 중복 확인 */
    async checkNickNmDuplicated({ commit }, params) {
      const { nickNm } = params;
      return window
        .axios({
          url: "/api/member/checkDuplicateNick",
          method: "POST",
          data: {
            nickNm,
          },
          isSilent: true,
        })
        .then((res) => {
          // 결과 가공 후 true / false 리턴
          return res.data.output === "N";
        });
      // return nickNm !== "테스트4";
    },
    /** 파라미터 추천인 아이디 확인 */
    async checkRecommenderId({ commit }, params) {
      const { mbrId } = params;
      return window
        .axios({
          url: "/api/member/checkRecommenderId",
          method: "POST",
          data: {
            mbrId,
          },
          isSilent: true,
        })
        .then((res) => {
          // 결과 가공 후 true / false 리턴
          return res.data.output === "N";
        });
      // return mbrId !== "test4";
    },
  },
};
