/* eslint-disable no-unused-vars */

export default {
  namespaced: true, // 모듈이 독립적으로 재사용

  state: {
    //변수 선언
    codeList: [],
    callResult: [],
  },

  getters: {
    //vue 호출(get)
    getCodeList: (state) => {
      return state.codeList;
    },
    getCallResult: (state) => {
      return state.callResult;
    },
  },

  mutations: {
    //vue 호출(set)
    setCodeList(state, codeList) {
      state.codeList = codeList;
    },
    setCallResult(state, result) {
      state.callResult = result;
    },
  },

  actions: {
    //서버단 호출
    selectCode({ commit, state }, params) {
      //공통코드 조회
      window
        .axios({
          method: "POST",
          url: "/api/selectCode",
          data: params,
        })
        .then((res) => {
          commit("setCodeList", res.data.input);
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
    async chkAccount({ commit, state }, params) {
      //계좌 실명인증

      return window
        .axios({
          method: "POST",
          url: "/api/callAccountNm",
          data: params,
        })
        .then((res) => {
          commit("setCallResult", res.data.output.body);
          return res.data.output.body.RSLT_CD;
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
    async updateBankInfo({ commit, state }, params) {
      //출금계좌 등록
      return window
        .axios({
          method: "POST",
          url: "/api/updateBankInfo",
          data: params,
        })
        .then((res) => {
          //commit("setCallResult", res.data.output.body);
          return res.data.input;
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
        });
    },
  },
};
