import Vue from "vue";

/**
 * axios 요청 메서드 공통화. import
 *
 */
const plugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$api = {
      /**
       * api 서버 요청 후 응답 직접 받음.
       *
       * 상태코드가 0000 이 아니면 오류
       *
       * 상태코드가 0000 이면 output 만 return 한다
       *
       * @params {string} url - api endpoint 포트 뒤 부분
       *
       * @params {object} input - body 부분
       *
       * @params {object} options
       *
       */
      // eslint-disable-next-line no-unused-vars
      async call(url, input, options) {
        return Vue.prototype
          .$axios({
            url,
            method: "POST",
            data: input,
          })
          .then((res) => {
            if (res.data?.code === "0000") {
              return res.data.output;
            }

            // 아니면 오류;
            throw new Error(
              JSON.stringify({
                msg: res.data?.msg,
                code: res.data?.code ?? "9999",
              })
            );
          });
      },
    };
  },
};

Vue.use(plugin);
export default plugin;
