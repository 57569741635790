/*
 * 최초요청 데이터의 날짜형식 변환 함수
 */

/* eslint-disable no-unused-vars */
export function parseChartDataT(msg, termDiv) {
  let out = new Array(); //result array
  if (!msg) {
    for (let k = 0; k < 20; k++) {
      const now = new Date();
      let item = new Object(); //data set
      const day = getFormatDate(now.setDate(now.getDate() + k));
      item.date = day;
      item.close = null;
      item.volume = null;
      out.push(item);
    }
    return out;
  }
  const data = JSON.parse(msg.data).Output1; //json parse
  for (var i in data) {
    let item = new Object(); //data set
    if (termDiv === "3") {
      item.date = data[i][0];
    } else {
      item.date = parseDate((data[i][0] += data[i][1]));
    }
    item.open = numberFommat(data[i][2]);
    item.high = numberFommat(data[i][3]);
    item.low = numberFommat(data[i][4]);
    item.close = numberFommat(data[i][5]);
    item.volume = numberFommat(data[i][6]);
    item.buyOrSell = data[i][7];
    out.push(item);
  }
  //일 차트 20일 쌓이기전에 임시 set
  if (termDiv === "3" && out.length < 20) {
    let plusDate = new Array();
    for (var j = 0; j < 20; j++) {
      const now = new Date();
      let item = new Object(); //data set
      const day = getFormatDate(now.setDate(now.getDate() + j));
      let bool = false;
      for (var k in out) {
        if (!bool && day === out[k].date) {
          bool = true;
        }
      }
      if (!bool) {
        item.date = day;
        plusDate.push(item);
      }
    }
    out = [...new Set([...out, ...plusDate])];
  }
  return out;
}
function getTodayDate() {
  let today = new Date();
  let year = today.getFullYear(); // 년도
  let month = today.getMonth() + 1; // 월
  let date = today.getDate(); // 날짜
  return year + "" + month + "" + date;
}

function getFormatDate(item) {
  let date = new Date(item);
  var year = date.getFullYear().toString(); //yyyy
  var month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  var day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  return year.substring(0, 4) + "" + month + "" + day; //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
}
/*
 * [ 수신메시지 : 실시간 차트 데이터 ] 변환
 */
export function parseChartDataA(msg, termDiv) {
  if (!msg) {
    return {
      date: 0,
      open: 0,
      high: 0,
      low: 0,
      close: 0,
      volume: 0,
      amount: 0,
      buyOrSell: "",
    };
  }
  const data = JSON.parse(msg.data).Output1; //json parse
  let date;
  if (termDiv === "3") {
    date = data.szDate;
  } else if (termDiv === "2") {
    if (typeof data.szTime !== "undefined") {
      date = parseDate((data.szDate += data.szTime.substring(0, 4)));
    }
  }
  const out = {
    date: date,
    open: zeroRemove(data.szOpen),
    high: zeroRemove(data.szHigh),
    low: zeroRemove(data.szLow),
    close: zeroRemove(data.szClose),
    volume: zeroRemove(data.szVolume),
    amount: zeroRemove(data.szAmount),
    buyOrSell: data.szBuyOrSell,
  };
  return out;
}

/*
 * [ 수신메시지 : 거래량 누적거래량 누적거래대금 ] 변환
 */
export function parseDataVolume(msg) {
  if (!msg) {
    return {
      volume: 0,
      amount: 0,
    };
  }
  const data = JSON.parse(msg.data).Output1; //json parse
  let item = new Object(); //data set
  let volume = 0;
  let amount = 0;
  for (var i in data) {
    volume = volume + numberFommat(data[i][5]);
    amount = amount + numberFommat(data[i][6]);
  }
  item.totlVolume = volume; // 일일 누적 거래량
  item.totlAmount = amount; // 일일 누적 거래대금
  return item;
}

/**
 *
 * @param {*} msg
 * @returns object
 */
export function parseSymbolCurrPriceT(msg) {
  if (!msg) {
    return {
      symbol: "",
      date: 0,
      time: 0,
      open: 0,
      high: 0,
      low: 0,
      close: 0,
      volume: 0,
      preClose: 0,
      buyPrice: 0,
      sellPrice: 0,
      amount: 0,
    };
  }
  const data = JSON.parse(msg.data).Output1; //json parse
  return {
    symbol: data.szSymbol.trim(), //심볼코드
    date: data.szDate, //날짜
    time: data.szTime, //시간
    open: zeroRemove(data.fOpen), //시가
    high: zeroRemove(data.fHigh), //고가
    low: zeroRemove(data.fLow), //저가
    close: zeroRemove(data.fClose), //종가
    volume: zeroRemove(data.fVolume), //거래량
    preClose: zeroRemove(data.fPreClose), //전일종가
    buyPrice: zeroRemove(data.fBuyPrice), //구매가
    sellPrice: zeroRemove(data.fSellPrice), //판매가
    amount: zeroRemove(data.fAmount), //거래대금
  };
}

/*
 * [ 수신메시지 ] 데이터의 날짜형식 변환 함수
 */
export function parseDate(str) {
  if (str == null) {
    return "";
  }
  var yyyy = str.substr(0, 4);
  var mm = str.substr(4, 2);
  var dd = str.substr(6, 2);
  var hh = str.substr(8, 2);
  var mi = str.substr(10, 2);
  return new Date(yyyy, mm - 1, dd, hh, mi);
}

/*
 * [ 수신메시지 ] 데이터의 0000 제거 함수
 */
export function zeroRemove(str) {
  str = String(str);
  if (str == null) {
    return 0;
  }
  let result = str;
  let strLen = str.length;
  if (str.indexOf(".") > -1) {
    for (let i = str.length; i > 0; i--) {
      if (str.substring(i, i - 1) == ".") {
        strLen = i;
        break;
      }
    }
  }
  if (str.substring(0, 1) === 0) {
    for (let j = 0; j < str.length; j++) {
      if (str.substring(j, j + 1) !== "0" || str.length > 1) {
        result = str.substring(j, strLen);
        break;
      }
    }
  }
  return Number(result);
}

function numberFommat(data) {
  return Number(data.toFixed(0));
}
