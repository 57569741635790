<template>
  <ul class="one_depth">
    <li
      v-for="val in oneDepthList"
      :key="val.Array"
    >
      <a
        :class="{ active: isActive1(val) }"
        @click="changeActive(val)"
      >
        <span>{{ val.mainName }}</span>
      </a>
      <SlideUpDown
        :tag="'div'"
        :active="isActive1(val)"
      >
        <div class="two_depth">
          <ul>
            <li
              v-for="secVal in getTwoDepthList(val)"
              :key="secVal.Array"
            >
              <router-link
                :to="{
                  path: secVal.path,
                  hash: secVal.hash,
                  query: secVal.query,
                }"
                :class="{ active: !isActive2(secVal) }"
                class="link"
                @click="eventclick"
              >
                <span>{{ secVal.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </SlideUpDown>
    </li>
  </ul>
</template>

<script>
import NavTagData from "./navTagData.json";
import get from "lodash/get";
import SlideUpDown from "vue-slide-up-down";

export default {
  name: "NavTagHdFrmtMb",
  components: { SlideUpDown },
  data() {
    return {
      NavTagData,
      /** router path 의 첫번째 / 부분 */
      active1Path: get(this.$router.path?.split("/"), "[1]", ""),
    };
  },
  computed: {
    /** 전체 1depth 목록 중 hidden 제외하고 표시 */
    oneDepthList() {
      return this.NavTagData.filter((item) => !item.hiddenMenu);
    },
  },
  watch: {
    $route: function (to) {
      if (to) {
        this.active1Path = "/" + get(to.path?.split("/"), "[1]", "");
      }
    },
  },
  methods: {
    /** 선택된 1depth 의 2depth 중 hidden 제외하고 표시 */
    getTwoDepthList(oneDepth) {
      return oneDepth.subName.filter((item) => !item.hiddenMenu);
    },
    eventclick() {
      this.$emit("goTo", (this.openAuto = true));
    },
    changeActive(nav1Item) {
      if (this.active1Path !== nav1Item.path) {
        this.active1Path = nav1Item.path;
      } else {
        this.active1Path = null;
      }
    },
    isActive1(nav1Item) {
      return this.active1Path === nav1Item.path;
    },
    isActive2(nav2Item) {
      return this.active1Path && nav2Item.path.includes(this.active1Path);
    },
  },
};
</script>

<style lang="scss" scoped>
.one_depth > li > div {
  display: block !important;
}

.link {
  width: 100%;
  display: block;
}
</style>
