import Vue from "vue";

/**
 * 웹소켓으로 받는 작품 정보 상태 관리. 받는 메시지
 * */
export default {
  namespaced: true, // 모듈이 독립적으로 재사용
  state: {
    messageQue: [],
    websocket: new WebSocket(process.env.VUE_APP_WS_URL),
    // 이하는 각 작품 코드를 키로하는 오브젝트가 dynamic 하게 생성된다
    // 예 "_A00001": {}
    symbolData: {},
    refCounts: {},
  },

  getters: {
    getCurrentPriceBySymbolCd: ({ symbolData }) => {
      return (symbolCd) => {
        if (!symbolData["_" + symbolCd]) {
          Vue.set(symbolData, "_" + symbolCd, {
            curPrice: 0,
            prevPrice: 0,
            symbolCd,
          });
        }

        return symbolData["_" + symbolCd];
      };
    },
  },

  mutations: {},

  actions: {
    wsConnect({ dispatch, getters, state }) {
      const websocket = state.websocket;
      const messageQue = state.messageQue;

          // 특정 종목이 아닌 전문은 오픈 시점에 바로 요청
          // t9734 : 전 종목 현재가
      messageQue.push(
            connectMsg("t9734", {
              szGubun: "000000",
            })
          );

      websocket.onmessage = function (e) {
        if (!e?.data) {
          console.log("빈 메시지 받음");
        }

        const data = JSON.parse(e.data);

        const trcode = data.Header.trcode;

        const szSymbol = Array.isArray(data.Output1)
          ? data.Output1[0][0]
          : data.Output1.szSymbol;

        setOutput({ state, getters }, trcode, szSymbol, data);
      };

      dispatch("sendAll");
    },

    /**
     * 웹소켓 연결상태에 따라서 바로 발송하거나, 연결 직후 que 순으로 발송하도록 한다
     */
    sendAll({ state }, params = {}) {
      const websocket = state.websocket;
      const { message } = params;

      if (message) {
        state.messageQue.push(message);
      }

      if (websocket.readyState === 1) {
        // console.log("이미 연결되어 있음");

        if (state.messageQue.length > 0) {
          if (state.messageQue.length > 0) {
            state.messageQue.forEach((queMessage) => {
              websocket.send(queMessage);
            });
            state.messageQue.length = 0;
          }
        }
      } else {
        websocket.onopen = function () {
          // 오픈 전에 있던 개별 종목 요청이 있다면 오픈 이후 요청
          if (state.messageQue.length > 0) {
            if (state.messageQue.length > 0) {
              state.messageQue.forEach((queMessage) => {
                websocket.send(queMessage);
              });
              state.messageQue.length = 0;
            }
          }
        };
      }
    },

    attachSymbol({ state, dispatch }, { symbolCd }) {
      if (!state.refCounts["_" + symbolCd]) {
        state.refCounts["_" + symbolCd] = 0;
        dispatch("sendAll", { message: realTimeMsg("A", "91", symbolCd) });
      }
      state.refCounts["_" + symbolCd]++;
    },
    detachSymbol({ state, dispatch }, { symbolCd }) {
      if (state.refCounts["_" + symbolCd]) {
        state.refCounts["_" + symbolCd]--;
        if (state.refCounts["_" + symbolCd] === 0) {
          dispatch("sendAll", { message: realTimeMsg("U", "91", symbolCd) });
        }
      }
    },
  },
};

/**
 * 미들웨어에 최초요청시 송신메시지
 *
 * 단건 수신 요청
 */
function connectMsg(trcode, jsonObj) {
  //func T 고정
  let out = "";
  if (typeof jsonObj !== "undefined" && jsonObj !== "") {
    out = JSON.stringify({
      Header: {
        function: "T",
        termtype: "HTS",
        trcode: trcode,
        trid: 10,
      },
      Input1: jsonObj,
    });
  } else {
    out = JSON.stringify({
      Header: {
        function: "T",
        termtype: "HTS",
        trcode: trcode,
      },
    });
  }
  return out;
}

/**
 * 미들웨어 실시간 데이터 요청 송신메시지
 *
 * 지속 수신 요청
 */
// eslint-disable-next-line no-unused-vars
function realTimeMsg(func, trcode, symbol) {
  //func [ A:실시간요청 ] [ U:실시간요청해제 ]

  return JSON.stringify({
    Header: {
      function: func,
      termtype: "HTS",
      trcode: trcode,
    },
    Input1: {
      Key1: symbol,
    },
  });
}

/**
 * output 이 있으면 종목을 찾아 "_종목" 정보 업데이트
 * */
function setOutput({ getters }, trcode, symbolCd, data) {
  switch (trcode) {
    case "t9734": // 전 종목 현재가
      data.Output1.forEach((output) => {
        const [
          szSymbol,
          // eslint-disable-next-line no-unused-vars
          szDate,
          // eslint-disable-next-line no-unused-vars
          szTime,
          // eslint-disable-next-line no-unused-vars
          fOpen,
          // eslint-disable-next-line no-unused-vars
          fHigh,
          // eslint-disable-next-line no-unused-vars
          fLow,
          fClose,
          // eslint-disable-next-line no-unused-vars
          fVolumn,
          fPreClose,
          // eslint-disable-next-line no-unused-vars
          fBuyPrice,
        ] = output;

        const symbolCd = szSymbol.trim();
        const symbol = getters.getCurrentPriceBySymbolCd(symbolCd);
        Object.assign(symbol, {
          curPrice: fClose,
          prevPrice: fPreClose,
          symbolCd: symbolCd,
        });
      });
      break;
    case "91": // 실시간 체결
      if (data.Output1) {
        const { szOpen, szClose } = data.Output1;
        const symbol = getters.getCurrentPriceBySymbolCd(symbolCd);
        Object.assign(symbol, {
          curPrice: szClose,
          prevPrice: szOpen,
        });
      }
      break;
  }
}
