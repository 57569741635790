import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user.js";
import art from "@/store/modules/art.js";
import mts from "@/store/modules/mts.js";
import member from "@/store/modules/member.js";
import login from "@/store/modules/login.js";
import assets from "@/store/modules/assets.js";
import notice from "@/store/modules/notice.js";
import common from "@/store/modules/common.js";
import trade from "@/store/modules/trade.js";
import ws from "@/store/modules/ws.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getter: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    art,
    mts,
    member,
    login,
    assets,
    notice,
    common,
    trade,
    ws,
  },
});
