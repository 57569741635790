/* eslint-disable no-unused-vars */

import { zeroRemove } from "@/cmmn/socket-util.js";

export default {
  namespaced: true, // 모듈이 독립적으로 재사용
  state: {
    arts: [],
    artsMarketPrice: {
      price: [],
      orders: [],
    },
    categorys: [],
    art: {},
    artist: {},
    artInfo: {},
    flag: "R",
  },

  getters: {
    getArt: (state) => {
      // 작품 정보
      return state.art;
    },
    getArtIdentify(state) {
      // 작품 이름 [작품 id]
      let a = state.art;
      return a.title + "[" + a.symbol + "]";
    },
    getArtInfo: (state) => {
      //작품_작가 정보
      return state.artInfo;
    },
    getArts: (state) => {
      //작품 목록
      return state.arts;
    },
    getCategorys: (state) => {
      //작품 카테고리
      return state.categorys;
    },
    getArtsMarketPrice: (state) => {
      //작품 상세시세
      return state.artsMarketPrice;
    },
  },

  mutations: {
    //Vuex 의 데이터, state 값을 변경하는 로직 = setter
    assignArt(state, art) {
      Object.assign(state.art, art);
    },
    setArtInfo(state, artInfo) {
      state.artInfo = artInfo;
    },
    setArts(state, artList) {
      state.arts = artList;
    },
    setCategorys(state, categoryList) {
      state.categorys = categoryList;
    },
    setFavoritArts(state, i) {
      state.arts[i].favorYn = state.arts[i].favorYn === "Y" ? "N" : "Y";
    },
    setFavoritArtInfo(state, art) {
      art.favorYn = art.favorYn === "Y" ? "N" : "Y";
      let index = null;
      state.arts.filter(function (el, idx) {
        if (el.symbolCd.indexOf(art.symbolCd) >= 0) {
          index = idx;
        }
      });
      state.arts[index].favorYn =
        index != null
          ? state.arts[index].favorYn === "Y"
            ? "N"
            : "Y"
          : undefined;
    },
    setArtsMarketPrice(state, msgData) {
      let data = JSON.parse(msgData.data).Output1; //json parse
      let getTrcd = JSON.parse(msgData.data).Header.trcode;
      let out = new Array(); //result array
      for (let i in data) {
        let item = new Object(); //data set
        let index = null;
        switch (getTrcd) {
          //전 종목 현재가
          case "t9734":
            //소켓 메시지 데이터 object set
            item.symbol = data[i][0].trim() != null ? data[i][0].trim() : "";
            item.date = data[i][1] != null ? data[i][1] : 0;
            item.time = data[i][2] != null ? data[i][2] : 0;
            item.open = data[i][3] != null ? data[i][3] : 0;
            item.high = data[i][4] != null ? data[i][4] : 0;
            item.low = data[i][5] != null ? data[i][5] : 0;
            item.close = data[i][6] != null ? data[i][6] : 0;
            item.volume = data[i][7] != null ? data[i][7] : 0;
            item.preClose = data[i][8] != null ? data[i][8] : 0;
            item.buyPrice = data[i][9] != null ? data[i][9] : 0;
            item.sellPrice = data[i][10] != null ? data[i][10] : 0;
            item.prepare = item.close - item.preClose;
            if (item.prepare > 0) {
              item.updown = 1;
            } else if (item.prepare < 0) {
              item.updown = 2;
            } else {
              item.updown = 0;
            }
            break;
          //전종목 거래대금 조회
          case "t9507":
            //소켓 메시지 데이터 object set
            item.symbol = data[i][0].trim() != null ? data[i][0].trim() : 0;
            // item.date = data[i][1] != null ? data[i][1] : 0;
            // item.basePrice = data[i][2] != null ? data[i][2] : 0;
            // item.prevClose = data[i][3] != null ? data[i][3] : 0;
            item.amount = data[i][1] != null ? data[i][1] : 0;
            break;
        }
        //state.arts와 배열index 동기화
        state.arts.filter(function (el, idx) {
          if (el.symbolCd.indexOf(item.symbol) >= 0) {
            index = idx;
          }
        });
        if (index != null) {
          if (getTrcd === "t9734" && item.close === 0 && item.preClose > 0) {
            item.open = item.preClose;
            item.close = item.preClose;
          }
          out[index] = item;
        }
      }
      switch (getTrcd) {
        case "t9734":
          for (let i in state.arts) {
            if (out[i] == null || out[i].close === 0) {
              out[i] = {
                symbol: state.arts[i].symbolCd,
                date: 0,
                time: 0,
                open: state.arts[i].currPrice,
                high: state.arts[i].currPrice,
                low: state.arts[i].currPrice,
                close: state.arts[i].currPrice,
                volume: 0,
                preClose: state.arts[i].currPrice,
                buyPrice: state.arts[i].currPrice,
                sellPrice: state.arts[i].currPrice,
              };
            }
          }
          state.artsMarketPrice.price = out;
          break;
        case "t9507":
          for (let i in state.arts) {
            if (out[i] == null) {
              out[i] = {
                symbol: state.arts[i].symbolCd,
                // date: 0,
                // basePrice: 0,
                // prevClose: 0,
                amount: 0,
              };
            }
          }
          state.artsMarketPrice.orders = out;
          break;
      }
    },
    setArtRealTimePrice(state, msgData) {
      const data = JSON.parse(msgData.data).Output1;
      let index;
      state.artsMarketPrice.price.filter(function (el, idx) {
        if (el.symbol.indexOf(data.szSymbol.trim()) >= 0) {
          index = idx;
        }
      });
      //현재가 변경
      state.artsMarketPrice.price[index].close = zeroRemove(data.szClose);
      //등락금액 변경
      state.artsMarketPrice.price[index].prepare =
        state.artsMarketPrice.price[index].close -
        state.artsMarketPrice.price[index].preClose;
      //updown표시 변경
      if (state.artsMarketPrice.price[index].prepare > 0) {
        state.artsMarketPrice.price[index].updown = 1;
      } else if (state.artsMarketPrice.price[index].prepare < 0) {
        state.artsMarketPrice.price[index].updown = 2;
      } else {
        state.artsMarketPrice.price[index].updown = 0;
      }
    },
    setFlag(state, flag) {
      state.flag = flag;
    },
  },

  actions: {
    async artDetail({ commit, state }, params) {
      return await window
        .axios({
          method: "POST",
          url: "/api/art/artInfo",
          data: {
            symbolCd: params.symbolCd,
            mbrId: params.mbrId,
          },
        })
        .then((res) => {
          commit("setArtInfo", res.data.output);
          // 해당 리턴 값을 받으면 symbolCd 값이 저장
          if (res.data.output.symbolCd != null) {
            localStorage.setItem("currentSymbolCd", res.data.output.symbolCd);
            return res.data.output.symbolCd;
          }
        })
        .catch((error) => {
          console.log("todoInfo err : " + JSON.stringify(error));
          return error;
        });
    },
    async searchArtList({ commit, state }, params) {
      return await window
        .axios({
          method: "POST",
          url: "/api/art/artSearch",
          data: {
            cateCd: params.category,
            classCd: params.classCd,
            searchKeyword: params.keyword,
            tabCd: params.tabCd,
            mbrId: params.mbrId,
          },
        })
        .then((res) => {
          commit("setArts", res.data.output);
          return res.data.output;
        })
        .catch((error) => {
          console.log("artSearch err : " + JSON.stringify(error));
          return error;
        });
    },
    selectArtCategory({ commit, state }, params) {
      window
        .axios({
          method: "POST",
          url: "/api/art/artCategory",
        })
        .then((res) => {
          commit("setCategorys", res.data.output);
        })
        .catch((error) => {
          console.log("selectArtCategory err : " + JSON.stringify(error));
        });
    },
    //관심작품 클릭
    mergeFavor({ commit, state }, params) {
      if (params.mbrId == null) {
        return;
      }
      window
        .axios({
          method: "POST",
          url: "/api/art/mergeFavorite",
          data: {
            symbolCd: params.symbolCd,
            mbrId: params.mbrId,
          },
        })
        .then(() => {
          params.idx != null
            ? commit("setFavoritArts", params.idx)
            : commit("setFavoritArtInfo", params);
        })
        .catch((error) => {
          console.log("mergeFavor err : " + JSON.stringify(error));
        });
    },
    //관심작품 정렬
    sortArts({ commit, state }, params) {
      let sort1 = params.sort === "asc" ? 1 : -1;
      let sort2 = params.sort === "asc" ? -1 : 1;
      let arr1 = state.arts; //작품
      let arr2 = state.artsMarketPrice.price; //작품가격
      let arr3 = state.artsMarketPrice.orders; //작품거래대금
      switch (params.gubun) {
        //심볼명 정렬
        case "symbolNm":
          arr1.sort((a, b) => (a.symbolNm > b.symbolNm ? sort1 : sort2));
          arr1.forEach((element) => {
            arr2.sort(
              (a, b) =>
                element.symbolCd.indexOf(a.symbol) -
                element.symbolCd.indexOf(b.symbol)
            );
            arr3.sort(
              (a, b) =>
                element.symbolCd.indexOf(a.symbol) -
                element.symbolCd.indexOf(b.symbol)
            );
          });
          break;
        //현재가 정렬
        case "price":
          arr2.sort((a, b) =>
            (a.close != null ? a.close : 0) > (b.close != null ? b.close : 0)
              ? sort1
              : sort2
          );
          arr2.forEach((element) => {
            arr1.sort(
              (a, b) =>
                element.symbol.indexOf(a.symbolCd) -
                element.symbol.indexOf(b.symbolCd)
            );
            arr3.sort(
              (a, b) =>
                element.symbol.indexOf(a.symbol) -
                element.symbol.indexOf(b.symbol)
            );
          });
          break;
        //거래량 정렬
        case "volume":
          arr3.sort((a, b) =>
            (a.amount != null ? a.amount : 0) >
            (b.amount != null ? b.amount : 0)
              ? sort1
              : sort2
          );
          arr3.forEach((element) => {
            arr1.sort(
              (a, b) =>
                element.symbol.indexOf(a.symbolCd) -
                element.symbol.indexOf(b.symbolCd)
            );
            arr2.sort(
              (a, b) =>
                element.symbol.indexOf(a.symbol) -
                element.symbol.indexOf(b.symbol)
            );
          });
          break;
      }
    },
    artsReceiveMsg({ commit, state }, params) {
      const getTrcd = JSON.parse(params.data).Header.trcode;
      if (getTrcd !== "91") {
        //최초조회
        commit("setFlag", "R");
        commit("setArtsMarketPrice", params);
      } else {
        //실시간조회
        if (state.flag === "S") {
          return;
        }
        commit("setArtRealTimePrice", params);
      }
    },
    /**
     * 작품 페이지 진입할 때 이전 데이터 삭제.
     * 메인화면 등에서 이전 내용 잠깐 보이다가 새 내용 보이는 걸 방지
     */
    clearArtInfo({ commit }) {
      commit("setArtInfo", {});
    },
    changeFlag({ commit }, param) {
      commit("setFlag", param);
    },
  },
};
