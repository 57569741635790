export default {
  namespaced: true,

  state: {
    /** 공지사항 페이지 */
    noticePage: {
      list: null, //공지사항 게시글
    },
    /** 공지사항 상세 */
    notice: {},
    /** 메인화면 공지사항 목록 */
    mainNoticeList: [],
    // /** 메인화면 공지사항 상세 */
    // mainNotice: {},
  },

  getters: {
    /** 공지사항 리스트 */
    getNoticePage: (state) => {
      return state.noticePage;
    },
    /** 공지사항 상세 */
    getNotice: (state) => {
      return state.notice;
    },
    /** 메인화면 공지사항 목록 */
    getMainNoticeList: (state) => {
      return state.mainNoticeList;
    },
    /** 메인화면 공지사항 상세 */
    getMainNotice: (state) => {
      return state.mainNotice;
    },
  },

  mutations: {
    setNoticePage(state, noticePage) {
      state.noticePage = noticePage;
    },
    setNotice(state, notice) {
      state.notice = notice;
    },
    setMainNoticeList(state, mainNoticeList) {
      state.mainNoticeList = mainNoticeList;
    },
  },

  actions: {
    /** 공지사항 페이지 조회 */
    async fetchNoticePage({ commit }, params) {
      let noticeUrl = "/api/notice/list";
      if (params.size != null) {
        noticeUrl += "?size=" + params.size;
      }
      if (params.pageNum != null) {
        noticeUrl += "&page=" + params.pageNum;
      }

      return window
        .axios({
          url: noticeUrl,
          method: "POST",
          data: {
            entry: params.entry,
            keyword: params.keyword,
          },
        })
        .then((res) => {
          commit("setNoticePage", res.data.output);
          return res.data.output.total;
        });
    },
    /** 공지사항 상세 조회 */
    async fetchNotice({ commit }, params) {
      return window
        .axios({
          url: "/api/notice/info",
          method: "POST",
          data: {
            nttNo: params.nttNo,
            entry: params.entry,
            keyword: params.keyword,
          },
        })
        .then((res) => {
          commit("setNotice", res.data.output);
        });
    },
    /** 메인화면 공지사항 목록 조회 */
    // async fetchMainNoticeList({ commit }) {
    //   return window
    //     .axios({
    //       url: "/api/notice/list?page=1&size=3",
    //       method: "POST",
    //       data: {},
    //     })
    //     .then((res) => {
    //       commit("setMainNoticeList", res.data.output.list);
    //     });
    // },
    /**
     * 공지사항 페이지 진입할 때 이전 데이터 삭제. 이전 내용 잠깐 보이다가 새 내용 보이는 걸 방지
     */
    clearNotice({ commit }) {
      commit("setNotice", {});
    },
  },
};
