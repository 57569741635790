import Vue from "vue";
import "./plugins/axios";
import "./plugins/api";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toast from "@/components/cmmn/Toast";
import Loading from "@/components/cmmn/Loading";
import "./plugins/validator";
import "./plugins/flutter-bridge";
import "./plugins/design";
import "@/plugins/validator";

import "@/style/css/default.css";
import "@/style/css/layout.css";
import "@/style/css/common.css";
import "@/style/css/sub.css";
// import "@/style/css/fonts.css";
import "@/style/css/font_noto-sans.css";
import vueCookies from "vue-cookies";
// import vue-panzoom
import panZoom from "vue-panzoom";

Vue.config.productionTip = false;

// 공통 이벤트(함수) 수신 및 송신 체널 생성
Vue.prototype.$EventBus = new Vue();
window.eventBus = Vue.prototype.$EventBus;

// 전역 컴포넌트 등록

// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Toast", Toast);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("Loading", Loading);
Vue.use(vueCookies);
Vue.use(panZoom);
Vue.$cookies.config("7d");

// 로그인 정보 초기화 후 렌더링. 저장된 로그인 정보가 없다면 비회원 상태로 렌더링한다
store.dispatch("login/initLogin").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
