/* eslint-disable no-unused-vars */

import {
  parseSymbolCurrPriceT,
  parseChartDataA,
  parseChartDataT,
  parseDataVolume,
} from "@/cmmn/socket-util.js";
// import { isEmpty } from "lodash-es";

export default {
  namespaced: true,

  state: {
    //차트
    termDiv: "3",
    chart: "",
    chartMsg: {},
    chartData: [{}],

    //호가
    priceMsg: {},
    sellList: null,
    buyList: null,

    //거래량 누적거래량 누적거래대금
    volumes: {
      totlVolume: 0, //누적 거래량
      totlAmount: 0, //누적 거래대금
    },
    //종목 현재가
    symbolCurrPrice: {},

    //실시간메시지 run stop 구분 (실시간 중지 날릴 시, 뒤늦게 수신받는 메시지 차단)
    flag: "R",
  },

  getters: {
    getchart: (state) => {
      //차트
      return state.chart;
    },
    getChartMsg: (state) => {
      //차트 메세지
      return state.chartMsg;
    },
    getPriceMsg: (state) => {
      //호가 메세지
      return state.priceMsg;
    },
    getSymbolCurrPrice: (state) => {
      //종목 현재가
      return state.symbolCurrPrice;
    },
    getChartData: (state) => {
      //차트 데이터
      return state.chartData;
    },
    getTermDiv: (state) => {
      //차트 구분
      return state.termDiv;
    },
    getToday: (state) => {
      return state.symbolCurrPrice;
      // return {
      //   open: state.todayOpen,
      //   high: state.todayHigh,
      //   low: state.todayLow,
      //   close: state.todayClose,
      // };
    },
    getVolumes: (state) => {
      return state.volumes;
    },
  },

  mutations: {
    assignChart(state, chart) {
      Object.assign(state.chart, chart);
    },
    setChartMsg(state, chartMsg) {
      if (Number(chartMsg.close) > Number(state.symbolCurrPrice.high)) {
        state.symbolCurrPrice.high = chartMsg.close;
      }
      if (Number(chartMsg.close) < Number(state.symbolCurrPrice.low)) {
        state.symbolCurrPrice.low = chartMsg.close;
      }
      state.symbolCurrPrice.volume =
        Number(state.symbolCurrPrice.volume) + Number(chartMsg.volume);
      state.symbolCurrPrice.amount = Number(chartMsg.amount);
      state.volumes.totlAmount =
        Number(state.volumes.totlAmount) + Number(chartMsg.close);
      state.chartMsg = chartMsg;
    },
    setPriceMsg(state, priceMsg) {
      state.priceMsg = priceMsg;
    },
    setSymbolCurrPrice(state, currMsg) {
      state.symbolCurrPrice = currMsg;
    },
    setChartData(state, chartData) {
      state.chartData = chartData;
    },
    setTermDiv(state, value) {
      state.termDiv = value;
    },
    setDataVolume(state, volumes) {
      state.volumes = volumes;
    },
    setFlag(state, flag) {
      state.flag = flag;
    },
  },

  actions: {
    receiveMsg({ commit, state }, param) {
      const getTrcd = JSON.parse(param.data).Header.trcode;
      const getFlag = JSON.parse(param.data).Message?.flag;
      switch (getTrcd) {
        case "t9733":
        case "92": //호가 실시간
          if (getFlag === "E") {
            commit("setPriceMsg", null);
            break;
          }
          commit("setPriceMsg", param);
          break;
        case "91": //차트 실시간
          if (getFlag === "E") {
            commit("setChartMsg", parseChartDataA(null));
            break;
          }
          if (state.flag === "S") {
            return;
          }
          commit("setChartMsg", parseChartDataA(param, state.termDiv));
          break;
        case "t9732": //종목 현재가
          if (getFlag === "E") {
            commit("setSymbolCurrPrice", parseSymbolCurrPriceT(null));
            break;
          }
          commit("setSymbolCurrPrice", parseSymbolCurrPriceT(param));
          break;
        case "t9731": //차트 리스트
          if (getFlag === "E") {
            commit("setChartData", parseChartDataT(null));
            break;
          }
          commit("setFlag", "R");
          commit("setChartData", parseChartDataT(param, state.termDiv));
          break;
        case "t9506": //거래량
          if (getFlag === "E") {
            commit("setDataVolume", parseDataVolume(null));
            break;
          }
          commit("setDataVolume", parseDataVolume(param));
          break;
      }
    },
    modifyTermDiv({ commit, state }, param) {
      commit("setTermDiv", param);
    },
    changeFlag({ commit }, param) {
      commit("setFlag", param);
    },
    clearChartData({ commit }) {
      commit("setPriceMsg", null);
      commit("setSymbolCurrPrice", parseSymbolCurrPriceT(null));
      commit("setChartMsg", parseChartDataA(null));
      commit("setChartData", parseChartDataT(null));
      commit("setDataVolume", parseDataVolume(null));
    },
  },
};
