<template>
  <div>
    <div
      class="layer_popup"
      :style="{
        display: displayShow ? 'block' : 'none',
        width: popupWidth + '%',
      }"
    >
      <div
        class="layer_header"
        style="text-align: center"
      >
        <strong>{{ title }}&nbsp;</strong>
        <a
          href="#"
          class="closed_popup"
          title="팝업닫기"
          @click="close()"
        >
          <span>팝업닫기</span>
        </a>
      </div>
      <div class="layer_contents">
        <div class="alignc">
          {{ msg }}
          <slot name="contentImg"></slot>
        </div>
        <div class="pop_btn_area">
          <button
            type="button"
            class="btn btn_bg04"
            title="닫기"
            :style="{ width: buttonWidth }"
            @click="close()"
          >
            <span>닫기</span>
          </button>
          <button
            v-if="type === 'CONFIRM'"
            type="button"
            class="btn btn_bg04"
            title="확인"
            :style="{ width: buttonWidth }"
            @click="close(true)"
          >
            <span>확인</span>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="displayShow"
      class="layer_popup-dim"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    popupWidth: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      messageStack: [],
    };
  },
  computed: {
    currentMessage() {
      if (this.messageStack.length === 0) {
        return {};
      }
      return this.messageStack[this.messageStack.length - 1];
    },
    displayShow() {
      return this.messageStack.length > 0;
    },
    title() {
      return this.currentMessage.title;
    },
    msg() {
      return this.currentMessage.msg;
    },
    type() {
      return this.currentMessage.type;
    },
    callback() {
      return this.currentMessage.callback;
    },
    buttonWidth() {
      switch (this.currentMessage.type) {
        case "CONFIRM":
          return "50%";
        case "ALERT":
        default:
          return "100%";
      }
    },
  },
  mounted() {},
  methods: {
    confirm(msg, { title, callback, image } = {}) {
      this.messageStack.push({ title, msg, callback, image, type: "CONFIRM" });
    },
    alert(msg, { title, callback, image } = {}) {
      this.messageStack.push({ title, msg, callback, image, type: "ALERT" });
    },
    close(b) {
      if (this.callback) {
        this.callback(b);
      }
      this.messageStack.pop();
    },
  },
};
</script>

<style scoped lang="scss">
.layer_popup-dim {
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  filter: alpha(opacity=50);
}
</style>
