<template>
  <div id="wrap">
    <div id="app">
      <!-- skip_nav -->
      <div id="skip_nav">
        <a href="#gnb">글로벌메뉴 바로가기</a>
        <a href="#content">본문내용 바로가기</a>
      </div>
      <!--// skip -->

      <!-- header -->
      <div id="header">
        <header>
          <!-- logo -->
          <div class="header">
            <h1>
              <router-link
                to="/"
                class="logo"
                title="ARTSTOCK 사이트로 이동"
              >
                <span>ARTSTOCK</span>
              </router-link>
            </h1>
            <!--// logo -->

            <!-- top_link -->

            <!-- 로그인 전 -->
            <div
              v-if="!isLogin"
              class="top_link"
            >
              <ul>
                <li>
                  <router-link
                    to="/user/login"
                    title="로그인 페이지로 이동"
                    >로그인</router-link
                  >
                </li>

                <li>
                  <router-link
                    to="/user/join/choose"
                    title="회원가입 페이지로 이동"
                    >회원가입</router-link
                  >
                </li>
              </ul>
            </div>
            <!-- 로그인 후 -->
            <div
              v-if="isLogin && getLoginMember"
              class="top_link"
            >
              <ul>
                <li>
                  <a
                    title="MYPAGE로 이동"
                    @click="selectPerBusi(getLoginMember)"
                    >마이페이지</a
                  >
                </li>
                <li>
                  <a
                    title="로그아웃 하기"
                    @click="onClickLogout()"
                    >로그아웃</a
                  >
                </li>
              </ul>
            </div>

            <!--// top_link -->

            <!-- gnb -->
            <!-- 1뎁스 활성화 시 2뎁스 메뉴가 항상열려있어야한다. -->
            <div
              id="gnb"
              class="gnb sub"
            >
              <NavTagHdFrmt></NavTagHdFrmt>
            </div>
            <!--// gnb -->

            <!-- m_gnb -->
            <!-- 모바일 버전 -->
            <div
              id="m_gnb"
              class="m_gnb"
            >
              <div class="m_gnb_area">
                <!-- 상단 고정 하단 고정을 위한 div 추가 -->
                <div>
                  <!-- m_gnb_top_area -->
                  <div class="m_gnb_top_area">
                    <router-link
                      to="/"
                      class="m_gnb_logo"
                      title="ARTSTOCK 사이트로 이동"
                    >
                      <span>ARTSTOCK</span>
                    </router-link>
                    <a
                      href="#"
                      class="m_gnb_closed_btn"
                      title="메뉴 닫기"
                      @click="closeMenu"
                    >
                      <span>닫기</span>
                    </a>
                  </div>
                  <!--// m_gnb_top_area -->
                  <!-- m_gnb_login_area -->
                  <div class="m_gnb_login_area">
                    <!-- 로그인 전 -->
                    <p
                      v-if="!isLogin"
                      class="login_txt"
                    >
                      로그인 후 진행해주세요.
                    </p>

                    <router-link
                      v-if="!isLogin"
                      to="/user/login"
                      href="javascript:;"
                      class="m_login_btn"
                      title="아트스탁 로그인 페이지로 이동"
                    >
                      <span>아트스탁 로그인</span>
                    </router-link>
                    <!-- 로그인 후 -->
                    <p
                      v-if="isLogin && getLoginMember"
                      class="login_txt02"
                    >
                      {{ getLoginMember.mbrNm }}님 환영합니다.
                    </p>

                    <a
                      v-show="isLogin && getLoginMember"
                      class="m_mypage_btn"
                      title="마이페이지 페이지로 이동"
                      @click="selectPerBusi(getLoginMember)"
                    >
                      <span>마이페이지</span>
                    </a>

                    <ul>
                      <!-- 로그인 전 -->
                      <li v-if="!isLogin">
                        <router-link
                          to="/user/join/choose"
                          title="회원가입 페이지로 이동"
                          href="javascript:;"
                          class="m_member_join_btn"
                        >
                          <span class="join_dim_pick">회원가입</span>
                        </router-link>
                      </li>
                      <li v-if="!isLogin">
                        <router-link
                          to="/user/findId/info"
                          title="아이디 찾기 페이지로 이동"
                          href="javascript:;"
                          class="m_find_id_btn"
                        >
                          <span>아이디 찾기</span>
                        </router-link>
                      </li>
                      <li v-if="!isLogin">
                        <router-link
                          to="/user/findPw/info"
                          title="비밀번호 찾기 페이지로 이동"
                          href="javascript:;"
                          class="m_find_pw_btn"
                        >
                          <span>비밀번호 찾기</span>
                        </router-link>
                      </li>

                      <!-- 로그인 후 -->
                      <li v-if="isLogin">
                        <a
                          class="m_logout_btn"
                          title="로그아웃"
                          @click="onClickLogout()"
                        >
                          <span>로그아웃</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <!--// m_gnb_login_area -->
                  <!-- m_gnb_menu -->
                  <div class="m_gnb_menu">
                    <NavTagHdFrmtMb></NavTagHdFrmtMb>
                  </div>
                </div>
                <!-- m_gnb_footer -->
                <div class="m_gnb_footer">
                  <div class="m_gnb_sub">
                    <!-- footer_link01 -->
                    <ul class="m_gnb_link01">
                      <li>
                        <router-link
                          to="/footer/policy1"
                          title="이용약관 페이지로 이동"
                          >이용약관</router-link
                        >
                      </li>
                      <li>
                        <router-link
                          class="link-privacy-policy"
                          to="/footer/policy2"
                          title="개인정보처리방침 페이지로 이동"
                          >개인정보처리방침</router-link
                        >
                      </li>
                    </ul>
                    <!--// footer_link01 -->
                  </div>
                  <div class="m_gnb_bottom">
                    <a
                      :href="goUrl('https://m.blog.naver.com/artstockmaster')"
                      class="btn_link_blog"
                      title="네이버 블로그 이동"
                      target="_blank"
                    >
                      <span>블로그</span>
                    </a>
                    <a
                      :href="
                        goUrl('https://www.instagram.com/artstock_official/')
                      "
                      class="btn_link_ins"
                      title="인스타 이동"
                      target="_blank"
                    >
                      <span>인스타</span>
                    </a>
                    <a
                      :href="
                        goUrl(
                          'https://www.facebook.com/profile.php?id=100000691060752'
                        )
                      "
                      class="btn_link_face"
                      title="페이스북 이동"
                      target="_blank"
                    >
                      <span>페이스북</span>
                    </a>
                    <a
                      :href="
                        goUrl(
                          'https://www.youtube.com/channel/UCH6hFhrCXAFGxzBvPTSWMXA'
                        )
                      "
                      class="btn_link_you"
                      title="유튜브 이동"
                      target="_blank"
                    >
                      <span>유튜브</span>
                    </a>
                  </div>
                </div>
                <!--// m_gnb_footer -->
              </div>
              <!--// m_gnb_menu -->
            </div>
            <!--// m_gnb -->
            <a
              v-if="showBackBtn"
              href="javascript:;"
              class="m_btn_back"
              title="뒤로가기 버튼"
              @click="backMenu"
            >
              <span>뒤로가기</span>
            </a>
            <a
              class="m_btn_refresh"
              @click="$router.go()"
            >
              <span>새로고침</span>
            </a>
            <a
              href="javascript:;"
              class="m_btn_menu"
              title="메뉴 버튼"
              @click="openMenu"
            >
              <span>메뉴열기</span>
            </a>
          </div>
          <!--// header -->
        </header>
      </div>
      <!--// header -->

      <router-view> </router-view>
      <loading ref="loading"></loading>
      <toast ref="toast"></toast>

      <!-- footer -->
      <div
        id="footer"
        class="sub"
      >
        <footer>
          <!-- top_footer_area -->
          <div class="top_footer_area">
            <div class="footer_con">
              <!-- footer_link01 -->
              <ul class="footer_link01">
                <li>
                  <router-link
                    to="/art/intro"
                    title="회사소개 페이지로 이동"
                    >회사소개</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/footer/policy1"
                    title="이용약관 페이지로 이동"
                    >이용약관</router-link
                  >
                </li>
                <li>
                  <router-link
                    class="link-privacy-policy"
                    to="/footer/policy2"
                    title="개인정보처리방침 페이지로 이동"
                    >개인정보처리방침</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/art/support/FAQ"
                    title="고객지원 페이지로 이동"
                    active-class="bold"
                    >고객지원</router-link
                  >
                </li>
              </ul>
              <!--// footer_link01 -->
            </div>
          </div>
          <!--// top_footer_area -->
          <!-- bottom_footer_area -->
          <div class="bottom_footer_area">
            <div class="footer_con">
              <router-link
                to="/"
                class="footer_logo"
                title="ART STOCK 메인페이지로 이동"
              >
                <span>ART STOCK</span>
              </router-link>
              <!-- address_list -->
              <div class="address_area">
                <p>
                  상호 : 아트스탁 주식회사 | 대표이사 : 김진호 | 사업자등록번호
                  : 241-88-01549 | 통신판매업신고 : 2021-서울송파-4089
                </p>
                <p>
                  주소 : 서울특별시 송파구 올림픽로 300, 롯데월드타워앤몰 30층
                </p>
                <p>
                  TEL : 1533-3290 / Email : help@artstock.com (평일: 10:00-17:00
                  (점심: 12:00~13:00), 토/일 공휴일 휴무)
                </p>
                <p>Copyright ⓒ 2022 Artstock.Inc. All Rights Reserved.</p>
              </div>
              <!--// address_list -->
              <!-- footer_sns_box -->
              <div class="footer_sns_box">
                <a
                  href="https://blog.naver.com/artstockmaster"
                  class="btn_link_blog"
                  title="네이버 블로그 이동"
                  target="_blank"
                >
                  <span>블로그</span>
                </a>
                <a
                  href="https://www.instagram.com/artstock_official/"
                  class="btn_link_ins"
                  title="인스타 이동"
                  target="_blank"
                >
                  <span>인스타</span>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100000691060752"
                  class="btn_link_face"
                  title="페이스북 이동"
                  target="_blank"
                >
                  <span>페이스북</span>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCH6hFhrCXAFGxzBvPTSWMXA"
                  class="btn_link_you"
                  title="유튜브 이동"
                  target="_blank"
                >
                  <span>유튜브</span>
                </a>
              </div>
              <!--// footer_sns_box -->
            </div>
          </div>
          <!--// bottom_footer_area -->
        </footer>
      </div>
      <!--// footer -->

      <!-- m_quick_area -->
      <div class="m_quick_area">
        <ul>
          <li>
            <router-link
              to="/trade/FavoriteArts"
              title="시세 페이지로 이동"
            >
              <span>시세</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ path: '/trade/mtsDemo1', query: { subTab: 'subTab02' } }"
              title="현재가 페이지로 이동"
            >
              <span>현재가</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ path: '/trade/order', hash: '#mLocation' }"
              title="주문 페이지로 이동"
            >
              <span>주문</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                path: '/trade/assets',
                query: { tabs: 'pc_tab01' },
              }"
              title="자산 페이지로 이동"
            >
              <span>자산</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{
                path: '/trade/assets',
                query: { tabs: 'pc_tab05' },
              }"
              title="입금 페이지로 이동"
            >
              <span>입금</span>
            </router-link>
          </li>
        </ul>
      </div>
      <!--// m_quick_area -->

      <!-- 메인 팝업 컴포넌트 -->
      <LayerPopup
        ref="popup"
        :popup-width="40"
      ></LayerPopup>

      <div
        class="dim"
        :class="{ active: isDimActive }"
      ></div>
      <div
        class="layer_dim"
        :class="{ active: popupDimActive }"
      ></div>
      <!-- 메인 팝업 컴포넌트 -->
    </div>
  </div>
</template>

<script>
// store
import { mapGetters, mapActions } from "vuex";
// components
import NavTagHdFrmt from "@/components/navigateTab/NavTagHdFrmt";
import NavTagHdFrmtMb from "@/components/navigateTab/NavTagHdFrmtMb";
import device from "./mixins/device";
import LayerPopup from "./components/cmmn/LayerPopup.vue";

export default {
  components: {
    // 네비게이션 바 데이터 내용
    NavTagHdFrmt,
    NavTagHdFrmtMb,
    // 메인 이벤트 팝업
    LayerPopup,
  },
  mixins: [device],
  data() {
    return {
      // 메인 이벤트 팝업 dim
      popupDimActive: false,
      isDimActive: false,
      // 뒤로가기 버튼
      showBackBtn: false,
      // fluter 플러그인 기능 사용 시 사용 변수
      isFlutter: this.$flutterBridge.isFlutter,
    };
  },
  computed: {
    ...mapGetters("login", ["isLogin", "getLoginMember"]),
  },
  watch: {
    $route: function (to) {
      this.showBackBtn = to.path !== "/";
    },
  },
  mounted() {
    // 모바일 사이드 바 + 링크 클릭시 -> div 해제
    $(
      ".dim, a.m_login_btn, span.join_dim_pick, a.m_find_id_btn, a.m_find_pw_btn, div.two_depth a, a.m_logout_btn, a.m_mypage_btn, a.m_gnb_logo, ul.m_gnb_link01"
    ).on("click", function (e) {
      if (!$(e.target).hasClass(".m_gnb")) {
        $("html").removeClass("pop");
        $(".m_gnb").removeClass("open");
        $(".dim").removeClass("active");
        $(".at_ly_wrapper").removeClass("open");
      }
    });
    $(".dim").on("click", function () {
      $(".at_ly_wrapper").css("display", "none");
    });
  },
  created() {
    // 공통 팝업 쓰기
    this.initFcmPopup();

    // 부모객체에서 공통 수신함수 등록
    this.$EventBus.$on("showToast", (msg) => {
      this.showToast(msg);
    });
    // 레이어팝업 alert, confirm 이벤트 등록
    this.$EventBus.$on("alert", ({ msg, title, callback, image }) =>
      this.$refs.popup.alert(msg, { title, callback, image })
    );
    this.$EventBus.$on("confirm", ({ msg, title, callback, image }) =>
      this.$refs.popup.confirm(msg, { title, callback, image })
    );
    this.$EventBus.$on("dimClose", () => this.dimDrive(false));
    this.$EventBus.$on("dimActive", () => this.dimDrive(true));

    // 소켓 통신
    this.wsConnect();

    const { mbrId } = this.getLoginMember;
    const deviceType = this.$flutterBridge.deviceType;

    if (deviceType !== "WEB") {
      this.saveDeviceInfo(mbrId);
    }
    // axios 요청과 응답 직전 공통 처리
    this.$axios.interceptors.request.use(
      (config) => {
        if (!config.isSilent) {
          // 화면 효과 없이 요청한게 아니라면
          // this.$refs.loading.show();
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.$axios.interceptors.response.use(
      (res) => {
        if (!res.config?.isSilent) {
          // 화면 효과 없이 요청한게 아니라면
          // this.$refs.loading.off();
        }

        if (res.data && res.data.code) {
          let code = Number(res.data.code);
          // FIXME: 오류 코드 및 동작 정의 필요
          // 1000 이상은 오류코드로 간주
          if (code >= 1000) {
            if (code < 5000) {
              this.$refs.toast.show(`[${res.data.code}] ${res.data.msg}`);
            } else if (code >= 9000 && code <= 9009) {
              this.$refs.popup.alert(
                "오류",
                `[${res.data.code}] ${res.data.msg}`,
                () => {
                  this.$refs.popup.close();
                }
              );
            }
          }
        }
        return res;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  methods: {
    ...mapActions("ws", ["wsConnect"]),
    ...mapActions("login", ["doLogout"]),

    // 로그인 정보
    loginUserWrap() {
      this.loginUser(this.$refs.userCheck.getLoginInfo);
    },
    /** 로그인 정보 삭제하고 / 이동 */
    onClickLogout() {
      // 페이지 이동
      this.$router.push("/");
      // 로그인 정보 삭제
      this.doLogout().then();
      // 갱신 하지 않으면 남아있던 페이지에 접근이 가능 할 수 있다
      this.$router.go();
    },
    // 클릭시 뒤로가기
    backMenu() {
      this.$router.go(-1);
    },
    // 마이페이지 기업, 개인 구분
    selectPerBusi(val) {
      if (val.corpRegNo == null) {
        this.$router.push({
          path: "/user/myPg/personal/info",
        });
      } else {
        this.$router.push({
          path: "/user/myPg/business/info",
        });
      }
    },
    // 링크 이동
    goUrl(val) {
      if (this.isFlutter) {
        if (val) {
          return "flutter://open?url=" + val;
        }
        return "";
      } else {
        return val;
      }
    },
    openMenu() {
      $("html").addClass("pop");
      $(".m_gnb").addClass("open");
      $(".dim").addClass("active");
    },
    closeMenu() {
      $("html").removeClass("pop");
      $(".m_gnb").removeClass("open");
      $(".dim").removeClass("active");
    },
    // 네이티브 브라우저 커스텀에 사용하는 팝업 [layerPopup]
    initFcmPopup() {
      // TODO: 적절한 위치로 이동
      this.$flutterBridge.handleFcmMessage(null, (result) => {
        // TODO: 수신한 푸시 메시지 팝업
        if (result?.detail) {
          const { title, body, image, path } = result.detail;

          if (path?.length > 0) {
            this.$EventBus.$emit("confirm", {
              msg: body,
              title,
              callback: (b) => {
                if (path && b) {
                  this.$router.push({
                    path: "/landing",
                    query: { link: path },
                  });
                }
              },
              image,
            });
          } else {
            this.$EventBus.$emit("alert", {
              msg: body,
              title,
              image,
            });
          }
        }
      });
    },
    dimDrive(boolean) {
      this.popupDimActive = boolean;
    },
  },
};
</script>

<style lang="scss">
@import "~@/style/cmmn";

.link-privacy-policy {
  color: white !important;
  font-weight: bold;
}
</style>
<style>
.m_gnb_area {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.m_gnb_footer {
  position: relative;
  width: 100%;
  bottom: 0px;
  padding: 10px 0px;
  background-color: #660099;
}
.m_gnb_bottom {
  width: 100%;
  font-size: 0px;
  color: black;
}

.m_gnb_bottom a {
  display: inline-block;
  width: 13%;
  height: 28px;
  text-indent: -9999px;
  margin: 15px;
}

.m_gnb_bottom a + a {
  margin-left: 10px;
}

.m_gnb_bottom a.btn_link_blog {
  background: url("./assets/images/icon/icon_sns_blog.png") no-repeat center
    center;
}

.m_gnb_bottom a.btn_link_ins {
  background: url("./assets/images/icon/icon_sns_ins.png") no-repeat center
    center;
}

.m_gnb_bottom a.btn_link_face {
  background: url("./assets/images/icon/icon_sns_face.png") no-repeat center
    center;
}

.m_gnb_bottom a.btn_link_you {
  background: url("./assets/images/icon/icon_sns_you.png") no-repeat center
    center;
}

.m_gnb_sub {
  width: 50%;
  padding-bottom: 14px;
  font-size: 10px;
}

.m_gnb_sub ul.m_gnb_link01 {
  display: inline-block;
  padding: 10px 0 0 10px;
}

.m_gnb_sub ul.m_gnb_link01 li {
  display: inline-block;
}

.m_gnb_sub ul.m_gnb_link01 li + li {
  margin-left: 15px;
}

.m_gnb_sub ul.m_gnb_link01 li a {
  font-size: 10px;
  color: #b9b9b9;
  letter-spacing: -0.04em;
}

.m_gnb_sub ul.m_gnb_link01 li a.bold {
  font-weight: 500;
  color: #ffffff;
}

.m_quick_area ul li:nth-child(1) a {
  background-image: url("~@/assets/images/icon/icon_m_quick_bg.png") !important;
}

.m_quick_area ul li:nth-child(2) a {
  background-image: url("~@/assets/images/icon/icon_m_quick_bg02.png") !important;
}

.m_quick_area ul li:nth-child(3) a {
  background-image: url("~@/assets/images/icon/icon_m_quick_bg04.png") !important;
}

.m_quick_area ul li:nth-child(4) a {
  background-image: url("~@/assets/images/icon/icon_m_quick_bg05.png") !important;
}

.m_quick_area ul li:nth-child(5) a {
  background-image: url("~@/assets/images/icon/icon_m_quick_bg06.png") !important;
}

.m_btn_refresh {
  display: none;
  position: absolute;
  width: 28px;
  height: 25px;
  top: 28px;
  right: 45px;
  font-size: 0px;
  text-indent: -9999px;
  background: url("~@/assets/images/m_nav_refresh_btn.png") no-repeat center
    center;
  background-size: 100%;
}
@media (min-width: 640px) and (max-width: 1024px) {
  .m_gnb_footer {
    padding: 10px 0px;
  }
  .m_gnb_bottom a {
    margin: 10px;
  }
}
@media (min-width: 581px) and (max-width: 1024px) {
  .m_gnb_footer {
    display: flex;
  }
  .m_gnb_bottom {
    text-align: right;
    margin-bottom: 0px;
    padding-right: 15px;
  }
}
@media (min-width: 460px) and (max-width: 639px) {
  .m_gnb_footer {
    padding: 5px 0px;
  }
  .m_gnb_bottom a {
    margin: 10px;
  }
}
@media (max-width: 580px) {
  .m_gnb_sub {
    width: 100%;
  }
  .m_gnb_bottom {
    text-align: center;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 640px) {
  .m_btn_refresh {
    display: block;
    width: 25px;
    height: 22px;
    top: 25px;
    right: 50px;
  }
}
@media only screen and (max-width: 411px) {
  .m_btn_refresh {
    display: block;
    width: 21px;
    height: 19px;
    top: 21px;
    right: 40px;
  }
}
.toastui-editor-contents p {
  margin: 0px !important;
}
</style>
