import { getAccessToken, getUsername, login, logout } from "../../cmmn/auth";

export default {
  namespaced: true,

  state: {
    isLogin: false,
    /** 기본 회원 정보. 계좌 비밀번호 설정 여부 */
    loginMember: {},
    username: null,
    passwdCheck: false,
  },

  getters: {
    /** 현재 회원 정보 */
    getLoginMember: (state) => {
      return state.loginMember;
    },
    /** 로그인 여부 */
    isLogin: (state) => {
      return state.isLogin;
    },
    /** 로그인 여부 */
    getUsername: (state) => {
      return state.username;
    },
    getPasswdCheck: (state) => {
      return state.passwdCheck;
    },
  },

  mutations: {
    setLoginMember(state, member) {
      state.loginMember = member;
    },
    setIsLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    setUsername(state, username) {
      state.username = username;
    },
    initLogin(state, obj) {
      state.isLogin = obj.isLogin;
      state.loginMember = obj.loginMember;
      state.username = obj.username;
    },
    setUrlcode(state, urlcode) {
      state.urlcode = urlcode;
    },
    setPasswdCheck(state, PasswdCheck) {
      state.PasswdCheck = PasswdCheck;
    },
    setMemberAccount(state, memberAccount) {
      state.memberAccount = memberAccount;
    },
  },

  actions: {
    /** 최상위에서 최초 실행. 로그인 정보 갱신 */
    async initLogin({ commit, dispatch }) {
      // TODO: 로컬 스토리지에 autoLogin 값이 있을 때에만 초기 로그인 정보를 불러옴
      // if (true) {
      return getAccessToken().then((token) => {
        if (token) {
          commit("setIsLogin", true);
          commit("setUsername", getUsername());
          return dispatch("fetchLoginMember");
        }
      });
      // }
    },
    /** 로그인한 현재 회원 정보 받아오기 */
    async fetchLoginMember({ commit, state, dispatch }) {
      if (!state.isLogin) {
        commit("setLoginMember", null);
      }
      const username = getUsername();
      return window
        .axios({
          method: "POST",
          url: "/api/member/select",
          data: {
            mbrId: username,
          },
        })
        .then(({ data }) => {
          // currentMember mutation 실행
          commit("setLoginMember", data.output);
          return data.output;
        })

        .catch((error) => {
          // 통신 오류 있으면 로그아웃 처리
          dispatch("doLogout");
          throw error;
        });
    },
    async doLogout({ commit }) {
      return logout().then(() => {
        commit("setIsLogin", false);
        commit("setLoginMember", null);
        commit("setUsername", null);
      });
    },
    async doLogin({ commit, dispatch }, params) {
      return login({ mbrId: params.mbrId, mbrPw: params.mbrPw })
        .then(() => {
          commit("setIsLogin", true);
          commit("setUsername", getUsername());
          return dispatch("fetchLoginMember");
        })
        .catch((e) => {
          dispatch("doLogout");
          // 로그인 오류와 관련된 화면처리는 e 전달 후, vue에서 처리
          throw e;
        });
    },
    /**
     * 로그인 실행 전, 기존회원(통합 진행x) 확인 후. 통합인증 필요하면 true
     */
    // eslint-disable-next-line no-unused-vars
    checkNeedAuth({ commit, dispatch }, params) {
      return window
        .axios({
          method: "POST",
          url: "/api/auth/select/gbn",
          data: {
            mbrId: params,
          },
        })
        .then((res) => {
          return res.data?.output !== "0" && res.data?.output !== 0;
        });
    },
  },
};
