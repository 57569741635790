<template>
  <ul class="one_depth">
    <li
      v-for="(val, idx) in oneDepthList"
      :key="idx"
    >
      <router-link
        :to="val.path"
        active-class="active"
      >
        <span>{{ val.mainName }}</span>
      </router-link>


      <div class="two_depth">
        <ul>
          <li
            v-for="(secVal, i) in getTwoDepthList(val)"
            :key="i"
          >
            <router-link
              :to="secVal.path"
              active-class="active"
            >
              <span>{{ secVal.name }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import NavTagData from "./navTagData.json";

export default {
  name: 'NavTagHdFrmtMb',
  data() {
    return {
      NavTagData,
    }
  },
  computed: {
    /** 전체 1depth 목록 중 hidden 제외하고 표시 */
    oneDepthList() {
      return this.NavTagData.filter(item => !item.hiddenMenu);
    }
  },
  methods: {
    /** 선택된 1depth 의 2depth 중 hidden 제외하고 표시 */
    getTwoDepthList(oneDepth) {
      return oneDepth.subName.filter(item => !item.hiddenMenu);
    },
  }
}
</script>

<style lang="scss">
// 테스트 위해서 마우스 hover 대신 focus 에서도 메뉴 활성되도록 변경
.gnb ul.one_depth>li {
  a:focus {
    &+.two_depth {
      display: block;
    }
  }
}

// 오류 수정
// 상황 예> 아티피디아 페이지에서 작품 매매, 아트스탁 등 앞선 항목 1depth 에 마우스를 올리면 2depth가 제대로 표시되지 않음
.gnb.sub ul.one_depth:hover>li:not(:hover) a.active+div.two_depth {
  display: none !important;
}
</style>